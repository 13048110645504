import { getPaymentMethodName, PaymentMethod } from "../PaymentMethod";

/**
 * Maps a given `PaymentMethod` to a corresponding CoatCheck payment method.
 *
 * @param paymentMethod - The payment method to map.
 * @returns The mapped CoatCheck payment method as a number.
 */
export function mapToCoatcheckPaymentMethod(paymentMethod: PaymentMethod): number {
	return paymentMethod === PaymentMethod.CASH ? PaymentMethod.CASH : paymentMethod + 20;
}

/**
 * Maps a given `PaymentMethod` to a corresponding CoatCheck payment method name.
 *
 * @param paymentMethod - The payment method to map.
 * @returns The mapped CoatCheck payment method name as a string.
 */
export function mapToCoatcheckPaymentMethodName(paymentMethod: PaymentMethod): string {
	return paymentMethod === PaymentMethod.CASH ? "" : getPaymentMethodName(paymentMethod);
}
