import { FormControlLabel, FormGroup } from "@mui/material";
import { memo } from "react";
import ToggleItem, { IToggleItemProps } from "./ToggleItem";

interface IToggleWithLabelProps extends IToggleItemProps {
	label: string;
}

const ToggleWithLabel = ({ label, ...rest }: IToggleWithLabelProps) => {
	return (
		<FormGroup>
			<FormControlLabel control={<ToggleItem {...rest} />} label={label} />
		</FormGroup>
	);
};

export default memo(ToggleWithLabel);
