import { Button, CircularProgress, Stack } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogChannel, WebviewApp } from "../../../constants";
import { SettingsConfig } from "../../../types";
import { useLogger } from "../../../utils/useLogger";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import ToggleWithLabel from "../../Layout/Toggle/ToggleWithLabel";
import { useTheme } from "../../Theme/ThemeWrapper";

interface AdminSettingsPreferenceProps {
	currentSettings: SettingsConfig;
	settingToggler: (key: keyof SettingsConfig) => void;
	currentHideSnoozed: number;
	setCurrentHideSnoozed: Dispatch<SetStateAction<number>>;
}

const AdminSettingsPreference = ({ currentSettings, settingToggler, currentHideSnoozed, setCurrentHideSnoozed }: AdminSettingsPreferenceProps) => {
	const { t } = useTranslation();
	const { bridge, container } = useTheme();
	const { warn } = useLogger();

	const [sendingLog, setSendingLog] = useState<boolean>(false);

	const toggleIconOutlinedInversed = useCallback(() => settingToggler("iconOutlineInversed"), [settingToggler]);
	const toggleSoundEffectEnabled = useCallback(() => settingToggler("isSoundEffectEnabled"), [settingToggler]);
	const toggleFastSync = useCallback(() => settingToggler("hasFastSync"), [settingToggler]);
	const toggleDebugEnabled = useCallback(() => settingToggler("isDebugEnabled"), [settingToggler]);
	const toggleSingleCategoryInverted = useCallback(() => settingToggler("singleCategory"), [settingToggler]);
	const toggleDirectPayInverted = useCallback(() => settingToggler("directPay"), [settingToggler]);
	const toggleShowImages = useCallback(() => settingToggler("showImages"), [settingToggler]);
	const toggleShowDescription = useCallback(() => settingToggler("showDescription"), [settingToggler]);

	const toggleHideSnoozed = useCallback(() => setCurrentHideSnoozed((prev: number) => (prev ? 0 : 1)), [setCurrentHideSnoozed]);

	const handleSendLogs = useCallback(async () => {
		if (container !== WebviewApp.reactNative || !bridge.bridgeActive) return;

		setSendingLog(true);
		try {
			const logFilesToUpload: string[] = await bridge.getLogFilesToUpload(currentSettings.kioskId);
			if (logFilesToUpload.length === 0) return;

			await bridge.uploadLogFiles(currentSettings.kioskId, logFilesToUpload);
		} catch (error: unknown) {
			warn("Failed to upload logs" + (error as Error).message, LogChannel.generic);
		}
		setSendingLog(false);
	}, [bridge, container, warn, currentSettings.kioskId]);

	return (
		<>
			<AdminDivider label={t("system.admin.settings.preferences")} />

			<ToggleWithLabel
				color="success"
				checked={currentSettings.showImages}
				onChange={toggleShowImages}
				label={t("system.admin.theme.preset.showImages")}
			/>
			<ToggleWithLabel
				color="success"
				checked={currentSettings.showDescription}
				onChange={toggleShowDescription}
				label={t("system.admin.theme.preset.showDescription")}
			/>
			<ToggleWithLabel
				color="success"
				checked={Boolean(currentHideSnoozed)}
				onChange={toggleHideSnoozed}
				label={t("system.admin.settings.hideSnoozed")}
			/>
			<ToggleWithLabel
				color="success"
				checked={currentSettings.iconOutlineInversed}
				onChange={toggleIconOutlinedInversed}
				label={`${t("system.admin.cloud.menu")} - ${t("system.admin.theme.preset.iconOutlineInversed")}`}
			/>
			<ToggleWithLabel
				color="success"
				checked={currentSettings.singleCategory}
				onChange={toggleSingleCategoryInverted}
				label={`${t("system.admin.cloud.menu")} - ${t("system.admin.theme.preset.isSingleCategoryEnabled")}`}
			/>
			<ToggleWithLabel color="success" checked={currentSettings.hasFastSync} onChange={toggleFastSync} label={t("system.admin.settings.hasFastSync")} />
			<ToggleWithLabel
				color="success"
				checked={currentSettings.directPay}
				onChange={toggleDirectPayInverted}
				label={t("system.admin.theme.preset.isDirectPayEnabled")}
			/>
			<ToggleWithLabel
				color="success"
				checked={currentSettings.isSoundEffectEnabled}
				onChange={toggleSoundEffectEnabled}
				label={t("system.admin.theme.preset.isSoundEffectEnabled")}
			/>

			{container === WebviewApp.reactNative && bridge.bridgeActive ? (
				<>
					<ToggleWithLabel
						color="success"
						checked={currentSettings.isDebugEnabled}
						onChange={toggleDebugEnabled}
						label={t("system.admin.settings.isDebugEnabled")}
					/>

					<Stack direction="row" alignItems="center" justifyContent="left">
						<Button
							variant="contained"
							color="success"
							onClick={handleSendLogs}
							disabled={!currentSettings.isDebugEnabled || sendingLog}
							endIcon={sendingLog ? <CircularProgress sx={{ marginLeft: "2rem" }} /> : null}
						>
							{t("system.admin.settings.sendLogs")}
						</Button>
					</Stack>
				</>
			) : null}
		</>
	);
};

export default memo(AdminSettingsPreference);
