/**
 * Enum for Coatcheck command type.
 *
 * printTicket is the print ticket command.
 * printExample is the print ticket example command.
 * getStatus is the get status command.
 * zReport is the generate Z-report command.
 */
export enum Command {
	printTicket = "PrintTicket",
	printExample = "PrintTicketExample",
	getStatus = "GetStatus",
	zReport = "GenerateCurrentZReport"
}

/**
 * Enum for Coatcheck Ticket Format
 * In the Coatcheck configuration you can set up to 20 different ticket formats.
 */
export enum Format {
	A = 0,
	B = 1,
	C = 2,
	D = 3,
	E = 4,
	F = 5,
	G = 6,
	H = 7,
	I = 8,
	J = 9,
	K = 10,
	L = 11,
	M = 12,
	N = 13,
	O = 14,
	P = 15,
	Q = 16,
	R = 17,
	S = 18,
	T = 19
}

/**
 * Enum for Coatcheck `Cut` type
 *
 * no - the printer will not perform any cut
 * byTicket - the printer will perform a cut after each ticket.
 * end - the printer will perform a cut at the end of the print job.
 */
export enum Cut {
	no = 0,
	byTicket = 1,
	end = 2
}

/**
 * Retrieve the Coatcheck format name from the value.
 * @param value the numeric value of the format
 * @returns the letter of the format
 */
export function getCcFormatName(value: number): string {
	const indexOfS = Object.values(Format).indexOf(value as unknown as Format);
	return Object.keys(Format)[indexOfS];
}
