import { Box, Checkbox, Typography } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutMethod, getCheckoutMethodName } from "../../../constants";
import { SettingsConfig } from "../../../types";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import InputSave from "../../Layout/Form/InputSave";
import ToggleWithLabel from "../../Layout/Toggle/ToggleWithLabel";

interface AdminSettingsCheckoutProps {
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	settingToggler: (key: keyof SettingsConfig) => void;
}

const AdminSettingsCheckout = ({ currentSettings, setCurrentSettings, settingToggler }: AdminSettingsCheckoutProps) => {
	const { t } = useTranslation();

	const toggleSkipPlaceNumberChoice = useCallback(() => {
		settingToggler("skipPlaceNumberChoice");
	}, [settingToggler]);

	const handlePlaceNumberLettersChange = useCallback(
		(value: string) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, placeNumberLetters: value }));
		},
		[setCurrentSettings]
	);

	const handleAvailableCheckoutChange = useCallback(
		(changedMethod: CheckoutMethod) => {
			const methods: CheckoutMethod[] = [...currentSettings.availableCheckoutMethods];
			const index: number = methods.indexOf(changedMethod);

			if (index === -1) {
				methods.push(changedMethod);
			} else {
				methods.splice(index, 1);
			}

			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availableCheckoutMethods: methods }));
		},
		[setCurrentSettings, currentSettings.availableCheckoutMethods]
	);

	return (
		<>
			<AdminDivider label={t("system.admin.settings.checkoutMethod")} />
			<Box>
				{Object.values(CheckoutMethod).map((value: string | CheckoutMethod) => {
					if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
					const method: CheckoutMethod = Number(value) as CheckoutMethod;
					const methodName: string = getCheckoutMethodName(method);
					const existing: boolean = currentSettings.availableCheckoutMethods.some((searchMethod: CheckoutMethod) => searchMethod === method);

					const handleClick = () => handleAvailableCheckoutChange(method);

					return (
						<Box key={method}>
							<Checkbox color="success" checked={existing} onChange={handleClick} sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }} />
							{t(`checkout.choose.${methodName}`)}
						</Box>
					);
				})}
			</Box>

			{currentSettings.availableCheckoutMethods.includes(CheckoutMethod.PLACE_NUMBER) ? (
				<>
					<Box sx={{ mt: "2rem" }}>
						<Typography>{t("system.admin.settings.placeNumberLetters")}</Typography>
						<InputSave
							color="secondary"
							label={t("system.admin.settings.placeNumber")}
							value={currentSettings.placeNumberLetters}
							onSave={(value) => handlePlaceNumberLettersChange(value.toString())}
							InputProps={{ inputProps: { maxLength: 4, minLength: 0 } }}
						/>
					</Box>

					<ToggleWithLabel
						color="success"
						checked={currentSettings.skipPlaceNumberChoice}
						onChange={toggleSkipPlaceNumberChoice}
						label={t("system.admin.settings.skipPlaceNumberChoice")}
					/>
				</>
			) : null}
		</>
	);
};

export default memo(AdminSettingsCheckout);
