/**
 * Font Enum
 */
export enum Font {
	openSans = "Open Sans",
	lato = "Lato",
	roboto = "Roboto",
	notoSans = "Noto Sans",
	raleway = "Raleway",
	montserrat = "Montserrat",
	oswald = "Oswald",
	lora = "Lora",
	sourceSansPro = "Source Sans Pro",
	hind = "Hind",
	droidSerif = "Droid Serif",
	architectsDaughter = "Architects Daughter",
	karla = "Karla",
	aleo = "Aleo",
	workSans = "Work Sans",
	prompt = "Prompt",
	notoCondensed = "Noto Condensed",
	londrinaSolid = "Londrina Solid"
}
