import { Add, InfoOutlined } from "@mui/icons-material";
import { Box, Card, CardActions, CardContent, Grid2 as Grid, Stack, Theme, Typography, alpha, darken, lighten } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActionType, ModalViewName } from "../../../constants";
import { useCurrency } from "../../../hooks/useCurrency";
import { ItemSelectable } from "../../../types";
import { isDarkColor } from "../../../utils/color";
import i18n from "../../../utils/i18n/i18n";
import { tItemInfo } from "../../../utils/i18nMenu";
import CounterButtonGroupSimple from "../../Layout/Buttons/CounterButtonGroupSimple";
import TouchArea from "../../Layout/Buttons/TouchArea";
import TouchButton from "../../Layout/Buttons/TouchButton";
import ProductImage from "../../Layout/Images/ProductImage";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./Item.scss";

export interface ItemProps {
	itemSelectable: ItemSelectable;
	itemCount: number;
	onNewCartItem: (itemSelectable: ItemSelectable, openDetail?: boolean, referral?: ModalViewName) => void;
	decreaseItemCart: (itemSelectable: ItemSelectable) => void;
	compactMode?: boolean;
	menuColumn?: number;
}

const ItemCard = ({ itemSelectable, itemCount, onNewCartItem, decreaseItemCart, compactMode, menuColumn }: ItemProps) => {
	const { theme, settings, hideSnoozed } = useTheme();
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const maxNumber: number = 99;
	const greaterThanMax: boolean = itemCount > maxNumber;
	const currentMenuColumn: number = menuColumn ?? settings.menuColumn;
	const isMediumColumn: boolean = currentMenuColumn === 2;
	const unavailable: boolean = itemSelectable.itemInfo.snoozed === "true";
	const isCompactMode: boolean = compactMode ?? false;
	const chosenTheme: Theme = isCompactMode ? theme.systemTheme : theme.customTheme;
	const chosenBoxAction: ActionType = isCompactMode ? ActionType.add : settings.boxAction;
	const chosenButtonAction: ActionType = isCompactMode ? ActionType.add : settings.buttonAction;
	const priceLabel = useMemo(() => currency(itemSelectable.itemInfo.price), [currency, itemSelectable]);

	const infoLabel = useMemo(
		() =>
			chosenButtonAction === ActionType.details
				? itemSelectable.hasFields
					? t("checkout.orderReview.customize")
					: t("checkout.orderReview.info")
				: t("checkout.item.addItem").toUpperCase(),
		[t, itemSelectable, chosenButtonAction]
	);

	const titleLabel = useMemo(() => tItemInfo(i18n.language, itemSelectable.itemInfo, "shortText"), [itemSelectable]);
	const descriptionLabel = useMemo(() => tItemInfo(i18n.language, itemSelectable.itemInfo, "longText"), [itemSelectable]);

	const greyColor: string = useMemo(
		() =>
			alpha(
				isDarkColor(chosenTheme.palette.background.paper)
					? lighten(chosenTheme.palette.background.paper, 0.1)
					: darken(chosenTheme.palette.background.paper, 0.1),
				0.8
			),
		[chosenTheme]
	);

	const greyColorMoreContrast: string = useMemo(
		() =>
			isDarkColor(chosenTheme.palette.background.paper)
				? lighten(chosenTheme.palette.background.paper, 0.2)
				: darken(chosenTheme.palette.background.paper, 0.2),
		[chosenTheme]
	);

	const handleIncrease = useCallback((): void => {
		onNewCartItem(itemSelectable);
	}, [itemSelectable, onNewCartItem]);

	const handleOpenDetail = useCallback((): void => {
		onNewCartItem(itemSelectable, true);
	}, [itemSelectable, onNewCartItem]);

	const handleDecrease = useCallback((): void => {
		decreaseItemCart(itemSelectable);
	}, [itemSelectable, decreaseItemCart]);

	return (
		<Grid size={{ xs: 12 / currentMenuColumn }} sx={hideSnoozed && unavailable ? { display: "none" } : null}>
			<Card
				className={`itemCard ${itemCount ? "selected" : ""} ${isMediumColumn ? "largeColumn" : ""}`}
				variant="outlined"
				sx={{
					borderColor: itemCount ? chosenTheme.palette.primary.main : greyColorMoreContrast,
					opacity: unavailable ? "0.6" : 1
				}}
			>
				{itemCount > 0 && (
					<TouchArea
						onClick={handleDecrease}
						className="itemCounter"
						sx={{
							borderTopColor: chosenTheme.palette.primary.main,
							borderLeftColor: chosenTheme.palette.primary.main,
							borderWidth: isMediumColumn ? "3.5rem" : "2.5rem",
							color: chosenTheme.palette.primary.contrastText
						}}
					>
						<Box
							sx={{
								top: isMediumColumn ? "-3rem" : "-2rem",
								left: isMediumColumn ? "-3rem" : "-2rem",
								width: isMediumColumn ? "3rem" : "2rem",
								height: isMediumColumn ? "3rem" : "2rem"
							}}
						>
							{!greaterThanMax ? <Typography fontSize={isMediumColumn ? "1.5rem" : "1.25rem"}>&times;</Typography> : null}
							<Typography fontSize={isMediumColumn ? "1.75rem" : "1.5rem"} fontWeight="bold" letterSpacing="-0.125rem">
								{greaterThanMax ? maxNumber : itemCount}
							</Typography>
							{greaterThanMax ? <Typography fontSize={isMediumColumn ? "1.5rem" : "1.25rem"}>+</Typography> : null}
						</Box>
					</TouchArea>
				)}

				<TouchArea
					tone={chosenBoxAction === ActionType.add ? "cart" : "tap"}
					className="itemClickable"
					onClick={chosenBoxAction === ActionType.add ? handleIncrease : handleOpenDetail}
					disabled={unavailable || chosenBoxAction === ActionType.none}
				>
					{!isCompactMode ? (
						<ProductImage
							imageName={settings.showImages ? itemSelectable.itemInfo.imageName : ""}
							parentCategoryIcon={itemSelectable.parentCategoryIcon}
							width={isMediumColumn ? "24.25rem" : "16.4581rem"}
							height={isMediumColumn ? "17.5rem" : "12rem"}
							alt={itemSelectable.itemInfo.imageName}
							iconColor={chosenTheme.palette.primary.main}
						/>
					) : null}

					<CardContent
						sx={{
							backgroundColor: greyColor,
							background: `linear-gradient(
								0deg, 
								${greyColor} 0%,
								${alpha(chosenTheme.palette.background.paper, 0)} 100%)`,
							padding: isMediumColumn ? "1rem" : "0.5rem"
						}}
					>
						<Stack justifyContent={"flex-start"}>
							<Typography
								gutterBottom
								variant="h6"
								component="div"
								sx={{
									// min-height should be as high as two rows of h6 (h6.lineHeight * 2 * h6.fontSize)
									minHeight: `calc( ${Number(chosenTheme.typography.h6.lineHeight) * (settings.showDescription ? 1 : 2)} * ${
										chosenTheme.typography.h6.fontSize
									} )`,
									display: "flex",
									justifyContent: "center",
									alignItems: "flex-start",
									overflow: "hidden"
								}}
							>
								{titleLabel}
							</Typography>
							{settings.showDescription ? (
								<Typography
									gutterBottom
									variant="subtitle1"
									component="div"
									color={chosenTheme.palette.text.secondary}
									sx={{
										minHeight: `calc( ${Number(chosenTheme.typography.subtitle1.lineHeight) * 2} * ${
											chosenTheme.typography.subtitle1.fontSize
										} )`,
										maxHeight: `calc( ${Number(chosenTheme.typography.subtitle1.lineHeight) * 3} * ${
											chosenTheme.typography.subtitle1.fontSize
										} )`,
										display: "flex",
										justifyContent: "center",
										alignItems: "flex-start",
										overflow: "hidden"
									}}
								>
									{descriptionLabel}
								</Typography>
							) : null}
						</Stack>
					</CardContent>
				</TouchArea>

				<CardActions className="card-footer" sx={{ backgroundColor: greyColor }}>
					{chosenButtonAction === ActionType.addRemove && (
						<CounterButtonGroupSimple onIncrement={handleIncrease} onDecrement={handleDecrease} counter={itemCount} />
					)}
					{chosenButtonAction === ActionType.none && (
						<TouchButton className="add-cart-btn" size={isMediumColumn ? "medium" : "small"} disabled={true} sx={{ opacity: 0 }}>
							{infoLabel}
						</TouchButton>
					)}
					{[ActionType.add, ActionType.details].includes(chosenButtonAction) && (
						<TouchButton
							color="primary"
							variant="contained"
							className="add-cart-btn"
							size={isMediumColumn ? "medium" : "small"}
							disabled={unavailable}
							disableElevation
							endIcon={chosenButtonAction === ActionType.add ? <Add /> : <InfoOutlined />}
							onClick={chosenButtonAction === ActionType.add ? handleIncrease : handleOpenDetail}
							tone={chosenButtonAction === ActionType.add ? "cart" : "tap"}
						>
							{infoLabel}
						</TouchButton>
					)}

					<Typography fontWeight="bold" className="itemPrice">
						{priceLabel}
					</Typography>
				</CardActions>
			</Card>
		</Grid>
	);
};

export default memo(ItemCard);
