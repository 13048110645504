import { Box, Button, Checkbox, Paper, Stack } from "@mui/material";
import { Dispatch, memo, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getPaymentMethodName, PaymentMethod, WebviewApp } from "../../../constants";
import { ElectronicPaymentSatispay, ElectronicPaymentStripe } from "../../../services/4Delivery";
import { PaymentErrorResponse, SettingsConfig } from "../../../types";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import InputSave from "../../Layout/Form/InputSave";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";

interface AdminSettingsPaymentProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	satispayDisabled: boolean;
	setSatispayDisabled: Dispatch<SetStateAction<boolean>>;
}

const AdminSettingsPayment = ({ isAdmin, currentSettings, setCurrentSettings, satispayDisabled, setSatispayDisabled }: AdminSettingsPaymentProps) => {
	const { t } = useTranslation();
	const { container, bridge } = useTheme();
	const message = useMessage();

	const handleTerminalSerialChange = useCallback(
		(value: string) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, terminalSerialNumber: value }));
		},
		[setCurrentSettings]
	);

	const postSatispayTest = useCallback(async (): Promise<boolean> => {
		const electronicPaymentSatispay = new ElectronicPaymentSatispay();

		message({
			title: t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		let success = false;

		await electronicPaymentSatispay
			.test()
			.then(() => {
				success = true;
				message({
					title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") })}: ${t("common.ok")}`,
					description: t("system.admin.settings.testOk"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			})
			.catch((err: PaymentErrorResponse) => {
				message({
					title: `${t("common.crud.testing", { item: t("checkout.paymentModal.type.satispayLabel") })}: ${t("common.error")}`,
					description: t(`system.error.${err.error_code}`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
		return success;
	}, [message, t]);

	const handleAvailablePaymentChange = useCallback(
		async (changedMethod: PaymentMethod) => {
			const methods: PaymentMethod[] = [...currentSettings.availablePaymentMethods];
			const index: number = methods.indexOf(changedMethod);

			const indexAlternativeStripeMethod: number = methods.indexOf(
				changedMethod === PaymentMethod.STRIPE ? PaymentMethod.STRIPE_RN : PaymentMethod.STRIPE
			);

			if (index === -1) {
				// add method
				switch (changedMethod) {
					case PaymentMethod.SATISPAY:
						await postSatispayTest().then((testResponse: boolean) => {
							if (testResponse) {
								methods.push(changedMethod); // you can't add Satispay: not enabled
							} else {
								setSatispayDisabled(true);
								message({
									title: t("common.warning"),
									description: t("system.admin.settings.satispayComing"),
									okCallback: () => {},
									okLabel: t("common.ok")
								});
							}
						});

						break;

					case PaymentMethod.STRIPE:
					case PaymentMethod.STRIPE_RN:
						if (indexAlternativeStripeMethod !== -1) {
							methods.splice(indexAlternativeStripeMethod, 1);
						}
						methods.push(changedMethod);
						break;

					default:
						methods.push(changedMethod);
						break;
				}
			} else {
				// remove method
				if (methods.length === 1) {
					message({
						title: t("common.warning"),
						description: t("system.admin.settings.paymentsMandatory"),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
					return; // at least one method must be enabled
				}
				methods.splice(index, 1);
			}

			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, availablePaymentMethods: methods }));
		},
		[setCurrentSettings, currentSettings.availablePaymentMethods, postSatispayTest, setSatispayDisabled, t, message]
	);

	const postTerminalTest = useCallback(async () => {
		const electronicPaymentStripe = new ElectronicPaymentStripe(currentSettings.terminalSerialNumber);

		message({
			title: t("common.crud.testing", { item: t("system.admin.settings.reader") }),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		await electronicPaymentStripe
			.test()
			.then(() => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.ok")}`,
					description: t("system.admin.settings.testOk"),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			})
			.catch((err: PaymentErrorResponse) => {
				message({
					title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.error")}`,
					description: t(`system.error.${err.error_code}`),
					okCallback: () => {},
					okLabel: t("common.ok")
				});
			});
	}, [currentSettings.terminalSerialNumber, message, t]);

	const stripeRnTest = useCallback(async () => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			await bridge
				.testUsbStripeReader()
				.then((response: boolean) => {
					if (response) {
						message({
							title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.ok")}`,
							description: t("system.admin.settings.testOk"),
							okCallback: () => {},
							okLabel: t("common.ok")
						});
					} else throw new Error("error");
				})
				.catch(() => {
					message({
						title: `${t("common.crud.testing", { item: t("system.admin.settings.reader") })}: ${t("common.error")}`,
						description: t(`system.error.reader_is_offline`),
						okCallback: () => {},
						okLabel: t("common.ok")
					});
				});
		}
	}, [bridge, container, message, t]);

	return (
		<>
			<AdminDivider label={t("system.admin.settings.payments")} />
			<Box>
				{isAdmin ? (
					<>
						{Object.values(PaymentMethod).map((value: string | PaymentMethod) => {
							if (isNaN(Number(value))) return null; //looping over a "Numeric ENUM" returns both names and values. Get rid of names!
							const method: PaymentMethod = Number(value) as PaymentMethod;
							const existing: boolean = currentSettings.availablePaymentMethods.some((searchMethod: PaymentMethod) => searchMethod === method);
							const isSatispay: boolean = method === PaymentMethod.SATISPAY;
							const isStripeRn: boolean = method === PaymentMethod.STRIPE_RN;

							const handleClick = () => handleAvailablePaymentChange(method);

							return (
								<Box key={method}>
									<Checkbox
										color="success"
										checked={existing}
										onChange={handleClick}
										sx={{ "& .MuiSvgIcon-root": { fontSize: "3rem" } }}
										disabled={isSatispay ? satispayDisabled : isStripeRn ? container !== WebviewApp.reactNative : false}
									/>
									{t(`checkout.paymentModal.type.${getPaymentMethodName(method)}`)}
								</Box>
							);
						})}
					</>
				) : null}

				{currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE) ? (
					<Paper elevation={0} sx={{ p: "2rem" }}>
						<Stack direction="row" alignItems="center" justifyContent="left">
							<InputSave
								color="secondary"
								label={t("system.admin.settings.terminalSerialNumber")}
								value={currentSettings.terminalSerialNumber}
								onSave={(value) => handleTerminalSerialChange(value.toString().toUpperCase())}
								InputProps={{ inputProps: { maxLength: 20, minLength: 10 } }} //should be 15 characters: STRIPE
								disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE) || !isAdmin}
							/>
							<Button
								sx={{ marginLeft: "0.25rem" }}
								variant="contained"
								color="success"
								onClick={postTerminalTest}
								disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE)}
							>
								{t("common.test").toUpperCase()}
							</Button>
						</Stack>
					</Paper>
				) : null}

				{currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE_RN) ? (
					<Paper elevation={0} sx={{ p: "2rem" }}>
						<Stack direction="row" alignItems="center" justifyContent="left">
							<Button
								sx={{ marginLeft: "0.25rem" }}
								variant="contained"
								color="success"
								onClick={stripeRnTest}
								disabled={!currentSettings.availablePaymentMethods.includes(PaymentMethod.STRIPE_RN)}
							>
								{t("common.crud.testing", { item: t("system.admin.settings.reader") })}
							</Button>
						</Stack>
					</Paper>
				) : null}
			</Box>
		</>
	);
};

export default memo(AdminSettingsPayment);
