import { Add, Remove } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { memo } from "react";
import { SoundEffectProps } from "../../../hooks/effects/useSoundEffect";
import "./CounterButtonGroup.scss";
import TouchButton from "./TouchButton";

interface CounterButtonGroupSimpleProps extends SoundEffectProps {
	counter: number;
	min?: number; // counter min value
	max?: number; // counter max value
	onDecrement: () => void;
	onIncrement: () => void;
}

const CounterButtonGroupSimple = ({ min = 0, max = 1000, muteSound, tone, ...rest }: CounterButtonGroupSimpleProps) => {
	return (
		<Stack className={`counterGroupSimple`} direction={"row"} justifyContent={"space-between"}>
			<TouchButton
				color={"secondary"}
				variant="contained"
				size="large"
				startIcon={<Remove />}
				disabled={rest.counter <= min}
				onClick={rest.onDecrement}
				muteSound={muteSound}
			/>
			<TouchButton
				color={"secondary"}
				variant="contained"
				size="large"
				startIcon={<Add />}
				disabled={rest.counter >= max}
				onClick={rest.onIncrement}
				muteSound={muteSound}
				tone={tone}
			/>
		</Stack>
	);
};

export default memo(CounterButtonGroupSimple);
