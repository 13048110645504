import { toTitleCase } from "../../utils/Strings";

export enum PrinterType {
	basiq = "RT",
	coatcheck = "CC"
}

export function getPrinterTypeName(value: string): string {
	const indexOfS = Object.values(PrinterType).indexOf(value as unknown as PrinterType);
	return toTitleCase(Object.keys(PrinterType)[indexOfS]);
}
