import { Box, Container, Grid2 as Grid, ThemeProvider } from "@mui/material";
import { cloneDeep } from "lodash-es";
import { memo, useCallback } from "react";
import { CheckoutMethod } from "../../constants";
import { defaultCart, defaultCheckoutMenu } from "../../constants/defaults";
import { cart_add, cart_createEmpty } from "../../hooks/useCart";
import { Cart, Category, CheckoutMenu, ItemSelectable, KioskMenu } from "../../types";
import ItemsCategoriesList from "../Checkout/Items/ItemsCategoriesList";
import Sidebar from "../Checkout/Sidebar/Sidebar";
import { useTheme } from "../Theme/ThemeWrapper";

interface AdminPreviewProps {
	kioskMenu: KioskMenu;
}

const AdminPreview = ({ kioskMenu }: AdminPreviewProps) => {
	const { theme, settings } = useTheme();

	const doNothing = useCallback(() => {}, []);

	let dummyMenu: CheckoutMenu = defaultCheckoutMenu;
	let dummyCart: Cart = defaultCart;

	// Read available Checkout Method from Configuration
	const methods: CheckoutMethod[] = settings.availableCheckoutMethods;
	// Refine methods excluding the ones not present in json menu
	const existingMethods = methods.filter((method: CheckoutMethod) =>
		kioskMenu.checkoutMenus.some((checkoutMenu: CheckoutMenu) => checkoutMenu.checkout === method)
	);
	// if there are available and existing checkout methods...
	let foundGoodOne: boolean = false;
	if (existingMethods.length > 0) {
		existingMethods.forEach((existingMethod: CheckoutMethod) => {
			if (foundGoodOne) return;

			try {
				const checkoutMenu: CheckoutMenu =
					kioskMenu.checkoutMenus.find((menu: CheckoutMenu) => menu.checkout === existingMethod) ?? defaultCheckoutMenu;
				dummyMenu = cloneDeep(checkoutMenu);

				// Altering Menu to show: max 2 categories in sidebar; only 1 subcategory; max 5 items
				dummyMenu.categories = dummyMenu.categories.filter((category: Category, index: number) => index < 2);
				dummyMenu.categories[0].children = [dummyMenu.categories[0].children[0]];
				dummyMenu.categories[0].children[0].itemSelectable = dummyMenu.categories[0].children[0].itemSelectable.filter(
					(item: ItemSelectable, index: number) => index < 5
				);

				dummyCart = {
					...dummyCart,
					items: cart_add(cart_createEmpty(dummyMenu.categories[0].children[0].itemSelectable[0], 1), dummyCart.items)
				};
				foundGoodOne = true;
			} catch (error) {
				dummyMenu = defaultCheckoutMenu;
				dummyCart = defaultCart;
			}
		});
	}

	return (
		<ThemeProvider theme={theme.customTheme}>
			<Box
				className="previewFrame"
				sx={{
					backgroundColor: theme.customTheme.palette.background.default,
					borderTop: `0.125rem solid ${theme.customTheme.palette.grey[500]}`
				}}
			>
				<Container>
					{dummyMenu ? (
						<>
							<Grid container spacing={0}>
								<Grid size={{ xs: dummyMenu?.categories?.length > 1 ? 2 : 1 }}>
									{dummyMenu?.categories?.length > 1 ? (
										<Sidebar
											menu={dummyMenu}
											changeSelectedCategory={doNothing}
											changeSelectedCategoryChild={doNothing}
											selectedCategory={dummyMenu.categories[0]}
											selectedCategoryChildCode={dummyMenu.categories[0].children[0].categoryCode}
										/>
									) : null}
								</Grid>
								<Grid size={{ xs: 10 }}>
									{dummyMenu.categories[0]?.children?.length > 0 && dummyCart ? (
										<ItemsCategoriesList
											selectedCategory={dummyMenu.categories[0]}
											cart={dummyCart}
											onNewCartItem={doNothing}
											decreaseItemCart={doNothing}
										/>
									) : null}
								</Grid>
							</Grid>
						</>
					) : null}
				</Container>
			</Box>
		</ThemeProvider>
	);
};

export default memo(AdminPreview);
