import { useState } from "react";
import { ModalViewName } from "../constants";
import { defaultModalView } from "../constants/defaults";
import { ModalView } from "../types";

/**
 * @property {ModalView} modal - The current modal state.
 * @property {Function} openModal - A function to open a modal.
 * @property {Function} closeModal - A function to close the current modal.
 */
interface useModalResponse {
	modal: ModalView;
	openModal: (viewName: ModalViewName, overlay?: boolean, referral?: ModalViewName) => void;
	closeModal: () => void;
}

/**
 * The useModal hook provides a way to manage the modal state in the app.
 *
 * @returns {useModalResponse} An object containing the modal state and functions to open and close modals.
 */
export const useModal = (): useModalResponse => {
	const [modal, setModal] = useState<ModalView>(defaultModalView);

	const openModal = (viewName: ModalViewName, overlay?: boolean, referral?: ModalViewName) => {
		setModal({ ...modal, open: true, name: viewName, overlay: overlay ?? false, referral: referral ?? undefined });
	};
	const closeModal = () => {
		if (modal.referral) {
			openModal(modal.referral);
		} else {
			setModal({ ...modal, open: false, name: undefined, overlay: false, referral: undefined });
		}
	};

	return { modal, openModal, closeModal };
};
