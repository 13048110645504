import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { HttpStatusCode } from "axios";
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRevenueServiceName, isValidRevenueService, RevenueService, Rt, WebviewApp } from "../../../constants";
import useRtPrinter from "../../../services/Rt/useRtPrinter";
import { PrintRtResponse, SettingsConfig, Vat } from "../../../types";
import { callAxiosResponseInterface } from "../../../utils/httpClient/AxiosWrapper";
import InputSave from "../../Layout/Form/InputSave";
import ToggleWithLabel from "../../Layout/Toggle/ToggleWithLabel";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";
import AdminSettingsRtVat from "./AdminSettingsRtVat";

interface AdminSettingsRtProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	menuVatList: Vat[];
	settingToggler: (key: keyof SettingsConfig) => void;
}

const AdminSettingsRt = ({ isAdmin, currentSettings, setCurrentSettings, menuVatList, settingToggler }: AdminSettingsRtProps) => {
	const { t } = useTranslation();
	const message = useMessage();
	const { bridge, container } = useTheme();
	const { RT, isCashSystemOutOfPaper, isCashSystemInError, resetPrintingQueue, processPrintingQueue, retrievePrintingQueue } = useRtPrinter();

	const validRevenueService: boolean = useMemo(
		() => isValidRevenueService(currentSettings.cashSystemRevenueServiceType),
		[currentSettings.cashSystemRevenueServiceType]
	);

	const toggleDigitalReceipt = useCallback(() => settingToggler("printDigitalReceipt"), [settingToggler]);
	const toggleVariationsSplit = useCallback(() => settingToggler("printVariationsSplit"), [settingToggler]);
	const toggleQRCode = useCallback(() => settingToggler("printNonFiscalQRCode"), [settingToggler]);
	const toggleSkipTallonRecap = useCallback(() => settingToggler("skipTallonRecap"), [settingToggler]);

	const handleCashSystemIPChange = useCallback(
		(value: string) => {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, cashSystemIP: value }));
			// await postCashSystemCheck(currentSettings.cashSystemRevenueServiceType);
		},
		[setCurrentSettings]
	);

	const formatCashSystemResponseWithModal = useCallback(
		(response: PrintRtResponse | callAxiosResponseInterface): void => {
			let isResponseOk: boolean = false;
			let genericErrorCode: string | undefined = undefined;
			let rtErrorCode: number | undefined = undefined;
			if (response.status === HttpStatusCode.Ok) {
				isResponseOk = !response.data.status.error === true;
				rtErrorCode = response.data.status.error ? response.data.status.errorCode : undefined;
			} else {
				genericErrorCode = response.data.error_code ?? undefined;
			}

			const formattedDescription: JSX.Element = (
				<Box>
					{isResponseOk
						? null
						: genericErrorCode
							? t(`system.error.${genericErrorCode}`)
							: (rtErrorCode as Rt.ErrorCode)
								? t(`system.error.cashSystem.rt.${rtErrorCode}`)
								: null}
					<Box fontSize={"0.8rem"}>
						<Typography fontSize={"0.8rem"}>{t("common.log")}</Typography>
						<pre>{JSON.stringify(response.data, null, 4)}</pre>
					</Box>
				</Box>
			);

			message({
				title: t("system.admin.settings.cashSystem") + ": " + t(isResponseOk ? "common.ok" : "common.error"),
				description: formattedDescription,
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		},
		[message, t]
	);

	const postCashSystemCheck = useCallback(
		async (revenueService: RevenueService) => {
			await RT.cashSystemCheckStatus().then((response: PrintRtResponse) => {
				if (response.status === 200) {
					if ((response.data.status.printerType ?? RevenueService.IT) !== revenueService) {
						message({
							title: "",
							description: t("system.admin.settings.warningRevenueService"),
							okCallback: () => {},
							okLabel: t("common.ok")
						});
					}
				} else {
					formatCashSystemResponseWithModal(response);
				}
			});
		},
		[RT, message, t, formatCashSystemResponseWithModal]
	);

	const handleRevenueServiceChange = useCallback(
		async (event: SelectChangeEvent<RevenueService>) => {
			const revenueService = event.target.value as RevenueService;
			const localValidRevenueService: boolean = isValidRevenueService(revenueService);

			setCurrentSettings((prev: SettingsConfig) => ({
				...prev,
				cashSystemRevenueServiceType: revenueService,
				isFiscalMode: localValidRevenueService ? prev.isFiscalMode : false,
				printDigitalReceipt: localValidRevenueService ? prev.printDigitalReceipt : false
			}));
			await postCashSystemCheck(revenueService);
		},
		[setCurrentSettings, postCashSystemCheck]
	);

	const toggleFiscalMode = useCallback(() => {
		setCurrentSettings((prev: SettingsConfig) => {
			let newIsFiscalMode: boolean = !prev.isFiscalMode;

			if (!validRevenueService) newIsFiscalMode = false;
			return {
				...prev,
				isFiscalMode: newIsFiscalMode,
				printDigitalReceipt: newIsFiscalMode === false ? false : prev.printDigitalReceipt,
				skipTallonRecap: newIsFiscalMode === true ? false : prev.skipTallonRecap
			};
		});
	}, [setCurrentSettings, validRevenueService]);

	const toggleReceiptTallonMode = useCallback(() => {
		setCurrentSettings((prev: SettingsConfig) => {
			const newReceiptTallonMode: boolean = !prev.receiptTallonMode;

			return {
				...prev,
				receiptTallonMode: newReceiptTallonMode,
				skipTallonRecap: newReceiptTallonMode === false ? false : prev.skipTallonRecap
			};
		});
	}, [setCurrentSettings]);

	const postCashSystemTest = useCallback(async () => {
		message({
			title: t("system.admin.settings.cashSystem"),
			description: t("system.admin.settings.alertWaitAction"),
			okCallback: () => {},
			okLabel: ""
		});

		await RT.cashSystemTest().then((response: PrintRtResponse) => formatCashSystemResponseWithModal(response));
	}, [RT, formatCashSystemResponseWithModal, message, t]);

	const postCashSystemClosure = useCallback(async () => {
		message({
			title: "",
			description: t("system.admin.settings.dailyClosureMsg"),
			okCallback: async () => {
				setTimeout(async function () {
					message({
						title: t("system.admin.settings.cashSystem"),
						description: t("system.admin.settings.alertWaitAction"),
						okCallback: () => {},
						okLabel: ""
					});

					await RT.cashSystemClosure().then((response: PrintRtResponse) => formatCashSystemResponseWithModal(response));
				}, 500);
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	}, [RT, formatCashSystemResponseWithModal, message, t]);

	const processQueueConfirm = useCallback((): void => {
		const queue = retrievePrintingQueue();
		if (queue.queue.length === 0) {
			message({
				title: t("system.admin.settings.cashSystem").toUpperCase(),
				description: t("system.admin.settings.isQueueEmpty"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		} else {
			message({
				title: t("system.admin.settings.cashSystem").toUpperCase(),
				description: t("system.admin.settings.processQueue", { item: queue.queue.length.toString() }),
				okLabel: t("common.print").toUpperCase(),
				okCallback: async () => {
					processPrintingQueue();
				},
				cancelLabel: t("common.cancel").toUpperCase() ?? "",
				cancelCallback: () => {},
				thirdActionLabel: t("system.admin.settings.actionEmptyQueue").toUpperCase() ?? "",
				thirdActionCallback: () => {
					resetPrintingQueue();
				}
			});
		}
	}, [retrievePrintingQueue, processPrintingQueue, resetPrintingQueue, message, t]);

	useEffect(() => {
		if (isCashSystemOutOfPaper) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError") + ": " + t("system.error.cashSystem.out-of-paper"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		}
		if (isCashSystemInError) {
			message({
				title: t("system.error.cashSystem.error").toUpperCase(),
				description: t("system.error.cashSystem.statusError"),
				okCallback: () => {},
				okLabel: t("common.ok").toUpperCase()
			});
		}
	}, [isCashSystemOutOfPaper, isCashSystemInError, message, t]);

	const handleOpenVirtual = useCallback(() => {
		if (container === WebviewApp.reactNative && bridge.bridgeActive) {
			bridge.changeWebviewUrl("http://" + currentSettings.cashSystemIP, true);
		} else {
			window.open("http://" + currentSettings.cashSystemIP, "_blank");
		}
	}, [currentSettings.cashSystemIP, bridge, container]);

	const handleFiscalModeClick = useCallback(() => {
		if (!validRevenueService) {
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.enableReceiptWarning"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
	}, [message, validRevenueService, t]);

	const handleDigitalReceipt = useCallback(() => {
		if (!currentSettings.isFiscalMode) {
			message({
				title: t("common.warning"),
				description: t("system.admin.settings.enableDigitalReceiptWarning"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		}
	}, [message, currentSettings.isFiscalMode, t]);

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" sx={{ width: "58%" }}>
					<InputSave
						color="secondary"
						label={t("system.admin.settings.cashSystemIP")}
						value={currentSettings.cashSystemIP}
						onSave={(value) => handleCashSystemIPChange(value.toString())}
						InputProps={{ inputProps: { maxLength: 15, minLength: 7 } }}
						disabled={!isAdmin}
						sx={{ width: "100%" }}
					/>
				</Stack>
				<FormControl fullWidth sx={{ width: "40%" }}>
					<InputLabel color="success">{t("system.admin.settings.cashSystemRevenueService")}</InputLabel>
					<Select
						color="success"
						value={currentSettings.cashSystemRevenueServiceType}
						label={t("system.admin.settings.cashSystemRevenueService")}
						onChange={handleRevenueServiceChange}
					>
						{Object.values(RevenueService).map((value: string) => (
							<MenuItem key={value} value={value}>
								<Typography>{value === RevenueService.none ? t("common.none") : getRevenueServiceName(value)}</Typography>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Stack>
			<Stack direction="row" justifyContent="space-between" sx={{ mt: "0.75rem", "& > button": { lineHeight: 1.25, minHeight: "3.5rem" } }}>
				<Button
					color="success"
					disabled={currentSettings.cashSystemIP === ""}
					sx={{ width: "15%" }}
					variant="contained"
					onClick={postCashSystemTest}
					size="small"
				>
					{t("common.test").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={currentSettings.cashSystemIP === "" || !isAdmin}
					sx={{ width: "15%" }}
					variant="contained"
					onClick={handleOpenVirtual}
					size="small"
				>
					{t("common.open").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={currentSettings.cashSystemIP === "" || !validRevenueService || !currentSettings.isFiscalMode}
					sx={{ width: "34%" }}
					variant="contained"
					onClick={postCashSystemClosure}
					size="small"
				>
					{t("system.admin.settings.dailyClosureCashSystem").toUpperCase()}
				</Button>
				<Button
					color="success"
					disabled={currentSettings.cashSystemIP === ""}
					sx={{ width: "34%" }}
					variant="contained"
					onClick={processQueueConfirm}
					size="small"
				>
					{t("system.admin.settings.processQueueAction").toUpperCase()}
				</Button>
			</Stack>
			&nbsp;
			{isAdmin ? (
				<>
					<hr />
					<Box onClick={handleFiscalModeClick}>
						<ToggleWithLabel
							color="success"
							checked={currentSettings.isFiscalMode}
							onChange={toggleFiscalMode}
							label={t("system.admin.settings.receipt")}
							disabled={!validRevenueService}
						/>
					</Box>
					<Box onClick={handleDigitalReceipt}>
						<ToggleWithLabel
							color="success"
							checked={currentSettings.printDigitalReceipt}
							onChange={toggleDigitalReceipt}
							label={t("system.admin.settings.digitalReceipt")}
							disabled={!currentSettings.isFiscalMode || !validRevenueService}
						/>
					</Box>
					<ToggleWithLabel
						color="success"
						checked={currentSettings.printVariationsSplit}
						onChange={toggleVariationsSplit}
						label={t("system.admin.settings.printVariationsSplit")}
					/>
					<ToggleWithLabel
						color="success"
						checked={currentSettings.printNonFiscalQRCode}
						onChange={toggleQRCode}
						label={t("system.admin.settings.printNotFiscalQRCode")}
					/>
					<ToggleWithLabel
						color="success"
						checked={currentSettings.receiptTallonMode}
						onChange={toggleReceiptTallonMode}
						label={t("system.admin.settings.receiptTallonMode")}
					/>
					<ToggleWithLabel
						color="success"
						checked={currentSettings.skipTallonRecap}
						onChange={toggleSkipTallonRecap}
						label={t("system.admin.settings.skipTallonRecap")}
						disabled={currentSettings.isFiscalMode || !currentSettings.receiptTallonMode}
					/>
					<hr />
					<AdminSettingsRtVat currentSettings={currentSettings} setCurrentSettings={setCurrentSettings} menuVatList={menuVatList} />
				</>
			) : null}
		</>
	);
};

export default memo(AdminSettingsRt);
