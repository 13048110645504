import { HttpStatusCode } from "axios";
import { default as i18n } from "i18next";
import { useTheme } from "../../components/Theme/ThemeWrapper";
import { LogChannel, Rt } from "../../constants";
import { emptyStringChar } from "../../constants/defaults";
import { errorCodeOutOfPaper } from "../../constants/enum/Rt";
import { PaymentIntentResponse, PrintingItem, PrintingQueue, PrintingResult, PrintRtResponse } from "../../types";
import { callAxios, callAxiosResponseInterface } from "../../utils/httpClient/AxiosWrapper";
import { byteSize } from "../../utils/Strings";
import { useLogger } from "../../utils/useLogger";
import { ReceiptRows } from "./ReceiptRows";

const bufferLimitKb: number = 4;
const bufferLimitByte: number = (bufferLimitKb - 0.5) * 1024; // 512 bytes are retained as free memory
const carriageReturnChar: string = "\n";

export const useRT = () => {
	const { log, warn } = useLogger();
	const { settings } = useTheme();

	const printingHandler = async (printingItem: PrintingItem): Promise<PrintingResult> => {
		let totalPrintingResult: PrintingResult = {
			data: null,
			isPrinted: true,
			isInError: false,
			message: ""
		};

		const printingQueue: PrintingQueue = splitPrintingItemIntoQueueOfChunks(printingItem);

		for (const item of printingQueue.queue) {
			if (!totalPrintingResult.isPrinted || totalPrintingResult.isInError) break; // if one item in the queue was not printed, then stop queue processing
			const partialResult: PrintingResult = await printSinglePrintingItem(item);
			totalPrintingResult = partialResult;
		}

		return totalPrintingResult;
	};

	const printSinglePrintingItem = async (printingItem: PrintingItem): Promise<PrintingResult> => {
		const printingResult: PrintingResult = {
			data: null,
			isPrinted: true,
			isInError: false,
			message: ""
		};

		await callCashSystem(printingItem, 1)
			.then((response) => {
				if (response?.status === HttpStatusCode.Ok) {
					if (response?.data?.status?.error === true) {
						if (response?.data?.status?.errorCode === errorCodeOutOfPaper) {
							printingResult.message = "out-of-paper";
						} else {
							throw new Error("non-blocking-error");
						}
						printingResult.isPrinted = false;
					}
					printingResult.data = response.data;
				} else {
					throw new Error("bad-response");
				}
			})
			.catch((errCode: string) => {
				printingResult.message = errCode;
				printingResult.isPrinted = false;
				printingResult.isInError = true;
			});

		return printingResult;
	};

	const cashSystemClosure = (): Promise<PrintRtResponse> => {
		const printingItem: PrintingItem = {
			commandsArray: [
				// ReceiptRows.comment("Clean and close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt),
				// ReceiptRows.comment("Fiscal Cash Register Closure"),
				ReceiptRows.write(Rt.Command.fiscalClosure, [Rt.Block.descriptionClose])
			]
		};

		return callCashSystem(printingItem);
	};

	const cashSystemCheckStatus = (): Promise<PrintRtResponse> => {
		const printingItem: PrintingItem = {
			commandsArray: [
				// ReceiptRows.comment("Clean and close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt)
			]
		};

		return callCashSystem(printingItem);
	};

	const cashSystemTest = (): Promise<PrintRtResponse> => {
		const printingItem: PrintingItem = {
			commandsArray: [
				// ReceiptRows.comment("Clean and close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt),
				// ReceiptRows.comment("Start Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalInit, "")]),
				// ReceiptRows.comment("Body Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   " + i18n.t("common.test")) + Rt.Block.descriptionClose)
				]),
				// ReceiptRows.comment("End Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalEnd, "")])
			]
		};

		return callCashSystem(printingItem);
	};

	const cashSystemPaymentCanceled = (paymentIntent: PaymentIntentResponse, label: string): Promise<PrintRtResponse> => {
		const labelCanceled: string = label.toUpperCase();
		const labelTransactionId: string = i18n.t("bill.transaction_id");
		const labelCartId: string = i18n.t("bill.cart_id");
		const transactionId: string = paymentIntent.transaction_id ?? "";
		const cartId: string = paymentIntent.kiosk_cart_id?.toString() ?? "";

		const printingItem: PrintingItem = {
			commandsArray: [
				// ReceiptRows.comment("Clean and close pending receipts in any"),
				ReceiptRows.write(Rt.Command.cleanAndCloseReceipt),
				// ReceiptRows.comment("Start Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalInit, "")]),
				// ReceiptRows.comment("Body Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   " + labelCanceled) + Rt.Block.descriptionClose)
				]),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   ") + Rt.Block.descriptionClose)
				]),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   " + labelTransactionId) + Rt.Block.descriptionClose)
				]),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   " + transactionId) + Rt.Block.descriptionClose)
				]),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   " + labelCartId) + Rt.Block.descriptionClose)
				]),
				ReceiptRows.write(Rt.Command.nonFiscal, [
					ReceiptRows.addBlock(Rt.Block.nonFiscalBody, ReceiptRows.spaceFiller("   " + cartId) + Rt.Block.descriptionClose)
				]),
				// ReceiptRows.comment("End Non-Fiscal doc."),
				ReceiptRows.write(Rt.Command.nonFiscal, [ReceiptRows.addBlock(Rt.Block.nonFiscalEnd, "")])
			]
		};

		return callCashSystem(printingItem);
	};

	const callCashSystem = (printingItem: PrintingItem, retries?: number): Promise<PrintRtResponse> => {
		const HOST = settings.cashSystemIP ?? "";
		const url = "http://" + HOST + ":8080/xonxoff_protocol.cgi";

		const textBody = buildTextBody(printingItem);

		return new Promise((resolve) => {
			callAxios(
				{
					method: "post",
					url: url,
					body: textBody,
					headers: JSON.stringify({
						"Content-Type": "text/plain"
					}),
					cashsystem: true
				},
				retries ?? 3
			)
				.then((response: unknown) => {
					const castResponse = response as PrintRtResponse;
					resolve(castResponse);
					log(
						`RT Key:${castResponse.data.status.key}, Error:${castResponse.data.status.error}, ErrCode:${castResponse.data.status.errorCode}, Inattivita:${castResponse.data.status.inattivita}`,
						LogChannel.cashSystem
					);
				})
				.catch((err: callAxiosResponseInterface) => {
					warn("RT error " + (err.status ?? undefined) + ": " + (err.data.error_code ?? undefined), LogChannel.cashSystem);
					resolve(err);
				});
		});
	};

	const buildTextBody = (printingItem: PrintingItem): string => {
		// filter out empty commands line ()
		return printingItem.commandsArray.filter((value: string) => Boolean(value)).join("\n");
	};

	const splitPrintingItemIntoQueueOfChunks = (printingItem: PrintingItem): PrintingQueue => {
		const printingQueue: PrintingQueue = { queue: [] };
		let queueIndex = 0;
		let chunkedPrintingItem: PrintingItem = { commandsArray: [] };
		let chunkByteLength: number = 0;

		printingItem.commandsArray.forEach((currentCommand: string) => {
			// filter out empty command lines
			if (currentCommand === emptyStringChar) return;

			const currentCommandLength: number = byteSize(currentCommand);

			if (chunkByteLength + currentCommandLength >= bufferLimitByte) {
				queueIndex++;
				chunkedPrintingItem = { commandsArray: [] };
				chunkByteLength = currentCommandLength;
			} else {
				chunkByteLength = chunkByteLength + byteSize(carriageReturnChar) + currentCommandLength; // length of carriageReturnChar taken in account
			}
			chunkedPrintingItem.commandsArray.push(currentCommand);

			printingQueue.queue[queueIndex] = chunkedPrintingItem;
		});

		return printingQueue;
	};

	return {
		printingHandler,
		printSinglePrintingItem,
		cashSystemClosure,
		cashSystemCheckStatus,
		cashSystemTest,
		cashSystemPaymentCanceled
	};
};
