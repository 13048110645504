import { Draggable } from "@hello-pangea/dnd";
import { ArrowCircleDown, ArrowCircleUp, DragHandle } from "@mui/icons-material";
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { upSellingItemNotOrdered } from "../../../constants/defaults";
import { UpSellingItem } from "../../../types";
import i18n from "../../../utils/i18n/i18n";
import { tItemInfo } from "../../../utils/i18nMenu";
import { useTheme } from "../../Theme/ThemeWrapper";
import TouchIcon from "../Buttons/TouchIcon";
import AdminDivider from "../Divider/AdminDivider";
import ToggleItem from "../Toggle/ToggleItem";

export type DraggableListItemProps = {
	item: UpSellingItem;
	index: number;
	isFirst: boolean;
	isLastEnabled: boolean;
	isFirstDisabled: boolean;
	isTop3: boolean;
	isThird: boolean;
	onExcludedChange: (pluId: string) => void;
	onVerticalSlide: (slidingPluId: string, direction: "up" | "down") => void;
};

const DraggableListItem = ({
	item,
	index,
	isFirst,
	isLastEnabled,
	isFirstDisabled,
	isTop3,
	isThird,
	onExcludedChange,
	onVerticalSlide
}: DraggableListItemProps) => {
	const { theme } = useTheme();
	const { t } = useTranslation();

	const handleTouch = useCallback((e: any) => e.stopPropagation(), []);
	const handleToggleExclude = useCallback(() => onExcludedChange(item.pluId), [onExcludedChange, item.pluId]);
	const handleMoveUp = useCallback(
		() => (isFirst || item.excluded ? {} : onVerticalSlide(item.pluId, "up")),
		[isFirst, item.excluded, item.pluId, onVerticalSlide]
	);
	const handleMoveDown = useCallback(
		() => (isLastEnabled || item.excluded ? {} : onVerticalSlide(item.pluId, "down")),
		[isLastEnabled, item.excluded, item.pluId, onVerticalSlide]
	);

	return (
		<Draggable draggableId={item.pluId} index={index} isDragDisabled={item.excluded}>
			{(provided, snapshot) => (
				<>
					{isFirstDisabled ? (
						<Box sx={{ maxWidth: "75%" }}>
							<AdminDivider label={t("system.admin.upSelling.excluded")} />
						</Box>
					) : null}
					<ListItem
						sx={{
							borderRadius: "1rem",
							border: `0.0625rem solid ${theme.systemTheme.palette.grey.A400}`,
							padding: isTop3 ? "1rem 1rem" : "0.25rem 1rem",
							margin: isThird ? "0.5rem 0 1rem" : "0.5rem 0",
							maxWidth: "75%"
						}}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						className={snapshot.isDragging ? "isDragging" : ""}
					>
						<ListItemAvatar>
							<Avatar
								sx={{
									backgroundColor: item.excluded ? "transparent" : isTop3 ? theme.systemTheme.palette.warning.light : "auto",
									width: isTop3 ? "2.8rem" : "2.5rem",
									height: isTop3 ? "2.8rem" : "2.5rem"
								}}
							>
								{snapshot.isDragging ? (
									<DragHandle />
								) : (
									<Typography fontSize={isTop3 ? "1.5625rem" : "1.125rem"} fontWeight="bold">
										{item.order === upSellingItemNotOrdered ? "-" : item.order}
									</Typography>
								)}
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={tItemInfo(i18n.language, item.itemSelectable?.itemInfo, "shortText")}
							sx={isTop3 ? { "&>span": { fontWeight: "bold" } } : null}
						/>
						<Stack direction="row" alignItems="center" onClick={handleTouch}>
							<ToggleItem remDimension={1.75} color="success" checked={!item.excluded} onChange={handleToggleExclude} sx={{ mx: "1rem" }} />
							<TouchIcon onClick={handleMoveUp}>
								<ArrowCircleUp
									sx={{
										width: "3.5rem",
										height: "3.5rem",
										mx: "0.25rem",
										color: isFirst || item.excluded ? "transparent" : theme.systemTheme.palette.secondary.main
									}}
								/>
							</TouchIcon>
							<TouchIcon onClick={handleMoveDown}>
								<ArrowCircleDown
									sx={{
										width: "3.5rem",
										height: "3.5rem",
										mx: "0.25rem",
										color: isLastEnabled || item.excluded ? "transparent" : theme.systemTheme.palette.secondary.main
									}}
								/>
							</TouchIcon>
						</Stack>
					</ListItem>
				</>
			)}
		</Draggable>
	);
};

export default DraggableListItem;
