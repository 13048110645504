/**
 * Enum for Coatcheck error codes
 * It could be used to handle the error codes returned by the Coatcheck API.
 *
 * Possible error codes are:
 * - 0: meaning no error occurred
 * - from 1001 to 1059: specific error codes
 */
export enum ErrorCode {
	noError = 0,
	TO_LOAD_DICTIONARY_DB = 1001,
	ID_LANGUAGE_MISSING = 1002,
	ID_LANGUAGE_NOT_FOUND_IN_DB = 1003,
	CURRENCY__MISSING = 1004,
	CURRENCY_NOT_FOUND_IN_DB = 1005,
	COMMAND_UNMANAGED = 1006,
	X_REPORT_IS_DISABLED = 1007,
	Z_REPORT_IS_DISABLED = 1008,
	THE_PRINTER_IS_NOT_READY = 1009,
	REPORT_EMPTY_NOTHING_TO_PRINT = 1010,
	ID_TICKET_MISSING = 1011,
	TICKET_ID_OUT_OF_RANGE = 1012,
	THE_TICKET_IS_DISABLED = 1013,
	TICKET_PRICE_MISSING = 1014,
	TICKET_PRICE_DECIMAL_SEPARATOR = 1015,
	TICKET_PRICE_NEGATIVE = 1016,
	START_TICKET_IS_MISSING = 1017,
	START_TICKET_NUMBER_OUT_OF_RANGE = 1018,
	STOP_TICKET_IS_MISSING = 1019,
	STOP_TICKET_NUMBER_OUT_OF_RANGE = 1020,
	START_NUMBER_IS_GREATER_THAN_THE_STOP = 1021,
	TICKET_FOR_PRESSURE_MISSING = 1022,
	TICKET_FOR_PRESSURE_OUT_OF_RANGE = 1023,
	SERIES_COUNTER_JOIN_MISSING = 1024,
	JOIN_NOT_VALID = 1025,
	Z_REPORT_RANGE_INFO_MISSING = 1026,
	DATE_INFO_IS_INVALID = 1027,
	DATE_HAS_WRONG_ORDER = 1028,
	RANGE_HAS_WRONG_ORDER = 1029,
	ID_ERROR = 1030,
	MISS_TICKET_TYPE = 1031,
	MAX_PRICE_TICKET = 1032,
	TICKET_TYPE_NOT_VALID = 1033,
	PIN_LENGTH = 1034,
	PIN_ONLY_NUMBERS_IS_ALLOWED = 1035,
	PIN_INFO_IS_MISSING = 1036,
	PIN_TYPE_IS_MISSING = 1037,
	PIN_TYPE_OUT_OF_RANGE = 1038,
	PIN_FIRST_DIGIT_EQUAL_ZERO = 1039,
	PASSWORD_MISSING = 1040,
	PASSWORD_LEN = 1041,
	WIFI_ERROR_TO_SET_PASSWORD = 1042,
	FIELD_QNT_IS_MISSING = 1043,
	QNT_TICKET = 1044,
	PULSE_PERIOD_INVALID_RANGE = 1045,
	PULSE_PERIOD_IS_MISSING = 1046,
	PULSE_DECODE_MIN_ACTIVATION_LEN = 1047,
	PULSE_DECODE_MIN_RELEASE_LEN = 1048,
	PULSE_DECODE_MIN_ACTIVATION_MISSING = 1049,
	PULSE_DECODE_MIN_RELEASE_MISSING = 1050,
	STOP_WINDOWS_TIME_INVALID_RANGE = 1051,
	CUT_MODE = 1052,
	FIELD_CUT_IS_MISSING = 1053,
	PRINTER_STATUS = 1054,
	NETWORK_DHCP_FLAG = 1055,
	NETWORK_INVALID_IP = 1056,
	NETWORK_INVALID_NETMASK = 1057,
	NETWORK_INVALID_GATEWAY = 1058,
	PRINTER_STATUS_HEADER_OPEN_OR_PAPER_END = 1059
}
