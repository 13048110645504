import { Add, Check, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography
} from "@mui/material";
import { memo, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsConfig } from "../../../types";
import { useMessage } from "../../MessageHandler/MessageService";
import { useTheme } from "../../Theme/ThemeWrapper";

interface AdminSettingsKioskProfileProps {
	onChooseKioskId: (kioskId: number) => void;
	currentSettings: SettingsConfig;
}

const AdminSettingsKioskProfile = ({ onChooseKioskId, currentSettings }: AdminSettingsKioskProfileProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const message = useMessage();

	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const availableIds: number[] = useMemo(
		() =>
			// looking for the first integer available between 101 and 199.
			// Available here means not used as Settings Kiosk Id yet.
			Array.from({ length: 99 }, (_, i) => i + 101).filter((key: number) => {
				return theme.config.settings.every((config: SettingsConfig) => config.kioskId !== key);
			}),
		[theme.config.settings]
	);

	const handleClickOpen = useCallback(() => setDialogOpen(true), []);

	const handleClose = useCallback((event: SyntheticEvent<unknown>, reason?: string) => {
		if (reason !== "backdropClick") {
			setDialogOpen(false);
		}
	}, []);

	const handleNewKioskId = useCallback(
		(event: SelectChangeEvent<number>) => {
			message({
				title: t("common.crud.new", { item: t("system.admin.settings.kioskProfile") + " " + event.target.value }),
				description: t("system.admin.settings.newKioskIdConfirm"),
				okCallback: () => onChooseKioskId(event.target.value as number),
				okLabel: t("common.yes"),
				cancelCallback: () => handleClose({} as SyntheticEvent<unknown>),
				cancelLabel: t("common.cancel") ?? ""
			});
		},
		[handleClose, message, onChooseKioskId, t]
	);

	return (
		<Box>
			<Stack direction="row" alignItems="top" justifyContent="space-between">
				{theme.config.settings.length > 1 ? (
					<List
						sx={{
							borderRadius: "1rem",
							border: `0.0625rem solid ${theme.systemTheme.palette.grey.A400}`,
							padding: "0.25rem 1rem",
							width: "60%"
						}}
					>
						{theme.config.settings.map((settingsConfig: SettingsConfig) => {
							const currentProfile: boolean = currentSettings.kioskId === settingsConfig.kioskId;
							const defaultProfile: boolean = settingsConfig.kioskId === 0;
							// const disabled: boolean = defaultProfile || currentProfile;

							const handleClickProfile = () => onChooseKioskId(settingsConfig.kioskId);

							return (
								<ListItemButton
									key={settingsConfig.kioskId}
									onClick={handleClickProfile}
									sx={{ "&.hidden": { display: "none" } }}
									className={defaultProfile ? "hidden" : ""}
								>
									<ListItemAvatar>{currentProfile ? <Check color="secondary" /> : null}</ListItemAvatar>
									<ListItemText color="secondary">
										<Typography color={currentProfile ? "secondary" : undefined}>
											{t("system.admin.settings.kioskProfile") +
												" " +
												(settingsConfig.kioskId !== 0 ? settingsConfig.kioskId : t("common.default"))}
										</Typography>
									</ListItemText>
									{currentProfile ? <RadioButtonChecked color="secondary" /> : <RadioButtonUnchecked />}
								</ListItemButton>
							);
						})}
					</List>
				) : null}
				<Box sx={{ maxWidth: "35%" }}>
					<Button
						variant="contained"
						color="success"
						onClick={handleClickOpen}
						disabled={false}
						startIcon={<Add />}
						sx={{ lineHeight: 1.25, minHeight: "3.5rem" }}
					>
						{t("common.crud.new", { item: t("system.admin.settings.kioskProfile") }).toUpperCase()}
					</Button>
					<Dialog disableEscapeKeyDown open={dialogOpen} onClose={handleClose}>
						<DialogTitle>{t("common.crud.new", { item: t("system.admin.settings.kioskProfile") }).toUpperCase()}</DialogTitle>
						<DialogContent>
							<Typography>{t("system.admin.settings.kioskProfileAvailable")}</Typography>
							<Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
								<FormControl fullWidth sx={{ mt: 1 }}>
									<InputLabel>{t("common.ellipsis")}</InputLabel>
									<Select value={""} label={t("system.admin.settings.kioskProfile")} onChange={handleNewKioskId}>
										{availableIds.map((availableId: number, index: number) => (
											<MenuItem key={index} value={availableId}>
												{availableId}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>{t("common.cancel")}</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</Stack>
		</Box>
	);
};

export default memo(AdminSettingsKioskProfile);
