import { ThemeProvider } from "@mui/material";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { WebviewApp } from "../../constants";
import { defaultConfig, defaultRestaurantInfo } from "../../constants/defaults";
import { initialBridge, useBridgeInit } from "../../hooks/useBridgeInit";
import { AppConfig, AppTheme, RestaurantInfo, SettingsConfig } from "../../types";
import { AppBridgeState } from "../../types/reactNative/bridgeOutput";
import { getRestaurantInfo, getStorageObject } from "../../utils/StorageWrapper";
import { buildTheme } from "./ThemeConfig";

interface ThemeContextProps {
	theme: AppTheme;
	setContextTheme: Dispatch<SetStateAction<AppTheme>> | null;
	settings: SettingsConfig;
	setContextSetting: Dispatch<SetStateAction<SettingsConfig>> | null;
	restaurantInfo: RestaurantInfo;
	setContextRestaurantInfo: Dispatch<SetStateAction<RestaurantInfo>> | null;
	hideSnoozed: number;
	setContextHideSnoozed: Dispatch<SetStateAction<number>> | null;
	restaurantId: string;
	setContextRestaurantId: Dispatch<SetStateAction<string>> | null;
	restaurantPwd: string;
	setContextRestaurantPwd: Dispatch<SetStateAction<string>> | null;
	stripeLocationId: string | null;
	setContextStripeLocationId: Dispatch<SetStateAction<string | null>> | null;
	container: WebviewApp;
	bridge: AppBridgeState;
}

// The config could be overridden by a localStorage config
const localStorageConfig = getStorageObject("localConfig") as AppConfig;
const localHideSnoozed = localStorage.getItem("localHideSnoozed");
const localRestaurantId = localStorage.getItem("username") ?? "";
const localRestaurantPwd = localStorage.getItem("password") ?? "";
const localStripeLocationId = localStorage.getItem("localStripeLocationId") ?? null;
const localRestaurantInfo: RestaurantInfo = getRestaurantInfo();

const chosenAppConfig: AppConfig = localStorageConfig ?? localRestaurantInfo.config;
const chosenHideSnoozed: number = localHideSnoozed ? parseInt(localHideSnoozed) : localRestaurantInfo.hide_snoozed;

// Build the MUI Theme using the chosen Configurations
const defaultTheme: AppTheme = buildTheme(chosenAppConfig);

export const ThemeContext = createContext<ThemeContextProps>({
	theme: defaultTheme,
	setContextTheme: null,
	settings: defaultConfig.settings,
	setContextSetting: null,
	restaurantInfo: defaultRestaurantInfo,
	setContextRestaurantInfo: null,
	hideSnoozed: defaultRestaurantInfo.hide_snoozed,
	setContextHideSnoozed: null,
	restaurantId: localRestaurantId,
	setContextRestaurantId: null,
	restaurantPwd: localRestaurantPwd,
	setContextRestaurantPwd: null,
	stripeLocationId: localStripeLocationId,
	setContextStripeLocationId: null,
	container: WebviewApp.version1,
	bridge: initialBridge
});

type Props = {
	children?: ReactNode;
};

export const ThemeWrapper = ({ children }: Props) => {
	const { isWebViewBridgeAvailable, bridge } = useBridgeInit();

	const [currentTheme, setContextTheme] = useState<AppTheme>(defaultTheme);
	const [currentSetting, setContextSetting] = useState<SettingsConfig>(defaultConfig.settings);
	const [currentRestaurantInfo, setContextRestaurantInfo] = useState<RestaurantInfo>(localRestaurantInfo);
	const [currentHideSnoozed, setContextHideSnoozed] = useState<number>(chosenHideSnoozed);
	const [currentRestaurantId, setContextRestaurantId] = useState<string>(localRestaurantId);
	const [currentRestaurantPwd, setContextRestaurantPwd] = useState<string>(localRestaurantPwd);
	const [currentStripeLocationId, setContextStripeLocationId] = useState<string | null>(localStripeLocationId);

	const currentContainer: WebviewApp = useMemo(() => {
		return isWebViewBridgeAvailable ? WebviewApp.reactNative : WebviewApp.version1;
	}, [isWebViewBridgeAvailable]);

	if (isWebViewBridgeAvailable) {
		if (localStripeLocationId) bridge.setStripeLocationId(localStripeLocationId);
		if (localRestaurantId) bridge.setUsername(localRestaurantId);
		if (localRestaurantPwd) bridge.setPassword(localRestaurantPwd);
	}

	useEffect(() => {
		if (isWebViewBridgeAvailable) bridge.setUsername(currentRestaurantId);
	}, [currentRestaurantId, bridge, isWebViewBridgeAvailable]);

	useEffect(() => {
		if (isWebViewBridgeAvailable) bridge.setPassword(currentRestaurantPwd);
	}, [currentRestaurantPwd, bridge, isWebViewBridgeAvailable]);

	useEffect(() => {
		if (isWebViewBridgeAvailable) bridge.setStripeLocationId(currentStripeLocationId);
	}, [currentStripeLocationId, bridge, isWebViewBridgeAvailable]);

	return (
		<ThemeContext.Provider
			value={{
				theme: currentTheme,
				setContextTheme,
				settings: currentSetting,
				setContextSetting,
				restaurantInfo: currentRestaurantInfo,
				setContextRestaurantInfo,
				hideSnoozed: currentHideSnoozed,
				setContextHideSnoozed,
				restaurantId: currentRestaurantId,
				setContextRestaurantId,
				restaurantPwd: currentRestaurantPwd,
				setContextRestaurantPwd,
				stripeLocationId: currentStripeLocationId,
				setContextStripeLocationId,
				container: currentContainer,
				bridge: bridge.store.getState()
			}}
		>
			<ThemeProvider theme={currentTheme.customTheme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
