import { Divider } from "@mui/material";
import { memo } from "react";
import ChipRem from "../Chip/ChipRem";

interface AdminDividerProps {
	label?: string;
}

const AdminDivider = ({ label }: AdminDividerProps) => {
	return (
		<Divider textAlign="left" sx={{ mb: "1rem", mt: "2.5rem" }}>
			{label ? <ChipRem color="info" label={label} sx={{ px: "0.75rem" }} /> : null}
		</Divider>
	);
};

export default memo(AdminDivider);
