/**
 * Enum for Rt printer error codes
 * It could be used to handle the error codes returned by the Rt API.
 *
 * Possible error codes are:
 * - 0: meaning no error occurred
 * - from 1 to 88: specific error codes
 */
export enum ErrorCode {
	noError = 0,
	erroreStampante = 1,
	cartaScontrino = 2,
	testinaAlzata = 3,
	connettoreScont = 4,
	temperaturaScont = 6,
	tastoErrato = 8,
	dataInferiore = 9,
	dataErrata = 10,
	sequenzaErrata = 11,
	valoreErrato = 13,
	nonPrevisto = 16,
	impossibileOra = 17,
	nonPossibile = 18,
	scrittaInvalida = 19,
	superaValore = 20,
	superaLimite = 21,
	chiudiScontrino = 23,
	chiudiPagamento = 24,
	mancaNonUtilizzato = 25,
	cassaInferiore = 26,
	oltreProgrammaz = 27,
	pcNonConnesso = 28,
	erroreCecksumCodiceFiscale = 30,
	totaleNegativo = 32,
	inserirePagamento = 33,
	initDgfe = 34,
	chiusuraGiorn = 35,
	noRestoSuBuonoPasto = 65,
	documentoGiaAnnullato = 66,
	documentoNonAnnullabile = 68,
	documentoNonAnnullabileAliquote = 69,
	dataDocumentoNonValida = 70,
	matricolaNonValida = 71,
	datiPerResoMerceNonValidi = 72,
	superaLimiteResoMerce = 73,
	documentoEmessoRt = 74,
	documentoEmessoMf = 75,
	resoAnnulloNonPossibilePerPiuDoc = 76,
	resoNonPossibileDiDocAnnullato = 77,
	noRestoSuPagamentoElettronico = 88
}

export const errorCodeOutOfPaper = ErrorCode.cartaScontrino;
