export enum ActionType {
	none = 0,
	add = 1,
	details = 2,
	addRemove = 3
}

export function getActionTypeName(value: number): string {
	const indexOfS = Object.values(ActionType).indexOf(value as unknown as ActionType);
	return Object.keys(ActionType)[indexOfS];
}
