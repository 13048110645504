import { Box, Stack, TableCell, TableRow, Typography, alpha, darken, lighten } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActionType } from "../../../constants";
import { useCurrency } from "../../../hooks/useCurrency";
import { areColorsContrasted, isDarkColor } from "../../../utils/color";
import i18n from "../../../utils/i18n/i18n";
import { tItemInfo } from "../../../utils/i18nMenu";
import CounterButtonGroup from "../../Layout/Buttons/CounterButtonGroup";
import TouchArea from "../../Layout/Buttons/TouchArea";
import ProductImage from "../../Layout/Images/ProductImage";
import { useTheme } from "../../Theme/ThemeWrapper";
import "./Item.scss";
import { ItemProps } from "./ItemCard";

const ItemRow = ({ itemSelectable, itemCount, onNewCartItem, decreaseItemCart }: ItemProps) => {
	const { theme, settings, hideSnoozed } = useTheme();
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const unavailable: boolean = itemSelectable.itemInfo.snoozed === "true";

	const palette = theme.customTheme.palette;

	const titleLabel = useMemo(() => tItemInfo(i18n.language, itemSelectable.itemInfo, "shortText"), [itemSelectable]);
	const descriptionLabel = useMemo(() => tItemInfo(i18n.language, itemSelectable.itemInfo, "longText"), [itemSelectable]);

	const greyColor: string = useMemo(
		() => alpha(isDarkColor(palette.background.paper) ? lighten(palette.background.paper, 0.05) : darken(palette.background.paper, 0.05), 0.8),
		[palette]
	);
	const greyColorMoreContrast: string = useMemo(
		() => (isDarkColor(palette.background.paper) ? lighten(palette.background.paper, 0.1) : darken(palette.background.paper, 0.1)),
		[palette]
	);

	const shadowSecondaryColor: string = useMemo(() => {
		let textShadowSecondaryColor = "transparent";
		if (!areColorsContrasted(palette.secondary.main, palette.background.default)) {
			textShadowSecondaryColor = alpha(isDarkColor(palette.secondary.main) ? palette.grey[100] : palette.grey[900], 0.7);
		}
		return textShadowSecondaryColor;
	}, [palette]);

	const handleClickItem = useCallback((): void => {
		if (unavailable || settings.boxAction === ActionType.none) return;
		onNewCartItem(itemSelectable, settings.boxAction === ActionType.details ? true : false);
	}, [itemSelectable, onNewCartItem, unavailable, settings.boxAction]);

	const handleDecrement = useCallback(() => decreaseItemCart(itemSelectable), [itemSelectable, decreaseItemCart]);
	const handleIncrement = useCallback(() => onNewCartItem(itemSelectable, false), [itemSelectable, onNewCartItem]);

	return (
		<TableRow
			className="itemRow"
			sx={{
				display: hideSnoozed && unavailable ? "none" : "flex",
				alignItems: "center",
				backgroundColor: greyColor,
				background: `linear-gradient(
						0deg, 
						${greyColorMoreContrast} 0%, 
						${greyColor} 2%, 
						${palette.background.paper} 50%,
						${palette.background.paper} 100%
					)`,
				marginBottom: "0.5rem",
				borderRadius: "0.5rem",
				opacity: unavailable ? "0.75" : 1
			}}
		>
			<TableCell className="col-button" colSpan={3}>
				{unavailable ? null : (
					<CounterButtonGroup counter={itemCount} quantityToCompare={0} onDecrement={handleDecrement} onIncrement={handleIncrement} tone="cart" />
				)}
			</TableCell>
			<TableCell className="col-desc" colSpan={7}>
				<TouchArea onClick={handleClickItem} muteSound={unavailable}>
					<Stack direction="row" spacing="1rem" alignItems="center">
						{settings.showImages ? (
							<ProductImage
								imageName={itemSelectable.itemInfo.imageName}
								parentCategoryIcon={itemSelectable.parentCategoryIcon}
								height="4.375rem"
								width="4.375rem"
								alt={itemSelectable.itemInfo.imageName}
								iconColor={palette.primary.main}
								iconFull={true}
							/>
						) : null}
						<Box sx={{ textAlign: "left" }}>
							<Typography variant="caption" fontSize="1.5rem" className="itemTitle">
								{titleLabel}
							</Typography>
							<Typography variant="subtitle1" className="itemDescription">
								{descriptionLabel}
							</Typography>
							{unavailable || settings.boxAction !== ActionType.details ? null : (
								<Typography
									variant="body2"
									color="secondary"
									className="underline"
									sx={{ textShadow: `0 0.0625rem 0.125rem ${shadowSecondaryColor}` }}
								>
									{t("checkout.orderReview." + (itemSelectable.hasFields ? "customize" : "info"))}
								</Typography>
							)}
						</Box>
					</Stack>
				</TouchArea>
			</TableCell>
			<TableCell className="col-price" colSpan={2} align="right">
				<Typography>{currency(itemSelectable.itemInfo.price)}</Typography>
			</TableCell>
		</TableRow>
	);
};

export default memo(ItemRow);
