import { CheckCircle } from "@mui/icons-material";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { cloneDeep, isEqual } from "lodash-es";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsConfig, Vat } from "../../../types";

interface AdminSettingsRtVatProps {
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	menuVatList: Vat[];
}
const AdminSettingsRtVat = ({ currentSettings, setCurrentSettings, menuVatList }: AdminSettingsRtVatProps) => {
	const { t } = useTranslation();

	/* The VAT's cash department must be set between 1 a 38 */
	const minCashDepartment: number = 1;
	const maxCashDepartment: number = 38;

	const currentVats = useMemo(() => {
		// merge VatList from the Menu and from previously uploaded configuration
		const newLocalVats: Vat[] = [...menuVatList];
		currentSettings.vatAssociations?.forEach((settingVat: Vat) => {
			const alreadyExistingVat: Vat | undefined = newLocalVats.find((menuVat: Vat) => settingVat.id === menuVat.id);
			if (alreadyExistingVat) {
				alreadyExistingVat.cashDepartment = settingVat.cashDepartment;
			} else {
				newLocalVats.push(settingVat);
			}
		});

		return [...newLocalVats];
	}, [menuVatList, currentSettings]);

	const [localVats, setLocalVats] = useState<Vat[]>([...cloneDeep(currentVats)]);

	const handleLocalVatChange = (changedVat: Vat) => {
		const newLocalVats: Vat[] = [...localVats];

		const alreadyExistingVat: Vat | undefined = newLocalVats.find((vat: Vat) => vat.id === changedVat.id);

		if (alreadyExistingVat && alreadyExistingVat.cashDepartment !== changedVat.cashDepartment) {
			alreadyExistingVat.cashDepartment = changedVat.cashDepartment;
		}
		setLocalVats([...newLocalVats]);
	};

	useEffect(() => {
		if (!isEqual(localVats, currentVats)) {
			setCurrentSettings((prev: SettingsConfig) => ({ ...prev, vatAssociations: [...localVats.filter((vat: Vat) => vat.cashDepartment !== "")] }));
		}
	}, [localVats, currentVats, setCurrentSettings]);

	return (
		<>
			<Typography>{t("system.admin.settings.vatMapping")}</Typography>
			<TableContainer component={Paper} elevation={1}>
				<Table
					sx={{
						minWidth: "40rem",
						"& td, & th": { padding: "1rem !important" }
					}}
					aria-label="simple-table"
				>
					<TableHead>
						<TableRow>
							<TableCell component="th" scope="row" align="center" colSpan={4}>
								{t("system.admin.settings.vat.title")}
							</TableCell>
							<TableCell align="center">{t("system.admin.settings.vat.cashDepartment")}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell component="th" scope="row">
								{t("system.admin.settings.vat.refCode")}
							</TableCell>
							<TableCell component="th" scope="row">
								{t("system.admin.settings.vat.rate")}
							</TableCell>
							<TableCell component="th" scope="row">
								{t("system.admin.settings.vat.breakdown")}
							</TableCell>
							<TableCell component="th" scope="row">
								{t("system.admin.settings.vat.goodType.title")}
							</TableCell>
							<TableCell component="th" scope="row"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{localVats.map((vat: Vat) => {
							const rate: string = parseFloat(vat.rate).toString() + "%";
							const exemption: string = vat.exemption !== "" ? "(" + vat.exemption + ")" : "";

							const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
								const newDep = e.target.value;

								if (newDep === "" || (Number(newDep) >= minCashDepartment && Number(newDep) <= maxCashDepartment)) {
									handleLocalVatChange({ ...vat, cashDepartment: e.target.value.toString() });
								}
							};

							return (
								<TableRow key={vat.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{vat.refCode}
									</TableCell>
									<TableCell component="th" scope="row">
										{rate}&nbsp;{exemption}
									</TableCell>
									<TableCell component="th" scope="row">
										{vat.breakdown ? <CheckCircle color="secondary" /> : null}
									</TableCell>
									<TableCell component="th" scope="row">
										{t(`system.admin.settings.vat.goodType.${vat.goodType}`)}
									</TableCell>
									<TableCell align="center" sx={{ p: "0.25rem" }}>
										<TextField
											type="number"
											InputProps={{
												inputProps: {
													min: minCashDepartment,
													max: maxCashDepartment,
													minLength: String(minCashDepartment).length,
													maxLength: String(maxCashDepartment).length
												}
											}}
											sx={{ "& input": { p: "0.5rem", textAlign: "center" } }}
											color="secondary"
											value={vat?.cashDepartment ?? ""}
											error={(vat?.cashDepartment ?? "") === ""}
											onChange={handleChange}
											disabled={!currentSettings.isFiscalMode}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default AdminSettingsRtVat;
