import { Close, Cloud, LogoutRounded, Refresh } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LogChannel, RoutePath } from "../../constants";
import { useWebview } from "../../hooks/useWebview";
import { useLogger } from "../../utils/useLogger";
import AdminDivider from "../Layout/Divider/AdminDivider";
import InputSave from "../Layout/Form/InputSave";
import { useMessage } from "../MessageHandler/MessageService";
import { useTheme } from "../Theme/ThemeWrapper";

interface AdminAccessProps {
	isAdmin: boolean;
	currentPin: string;
	setCurrentPin: (currentPin: string) => void;
	currentPinOperator: string;
	setCurrentPinOperator: (currentPinOperator: string) => void;
}

const AdminAccess = ({ isAdmin, currentPin, setCurrentPin, currentPinOperator, setCurrentPinOperator }: AdminAccessProps) => {
	const { restaurantId, setContextStripeLocationId, setContextRestaurantId, setContextRestaurantPwd } = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const message = useMessage();
	const { log } = useLogger();
	const { appClose, appReload } = useWebview();

	const [updatesFound, setUpdatesFound] = useState<boolean | null>(null);

	const handleLogout = () => {
		message({
			title: t("common.logout"),
			description: t("system.admin.access.logoutConfirm"),
			okCallback: () => {
				log(`Log out`, LogChannel.authentication);
				localStorage.clear();
				if (setContextRestaurantId) setContextRestaurantId("");
				if (setContextRestaurantPwd) setContextRestaurantPwd("");
				if (setContextStripeLocationId) setContextStripeLocationId(null);
				navigate(RoutePath.homePage);
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	};

	const handleUpdates = () => {
		try {
			navigator.serviceWorker.ready
				.then((registration) => {
					registration
						.update()
						.then(() => {
							setUpdatesFound(registration.installing !== null || registration.waiting !== null);
						})
						.catch((error) => {
							setUpdatesFound(false);
						});
				})
				.catch((error) => {
					setUpdatesFound(false);
				});
		} catch (error) {
			// console.log("nope");
		}
	};

	const handleCloseApp = () => {
		message({
			title: t("system.admin.access.closeApp"),
			description: t("system.admin.access.closeAppConfirm"),
			okCallback: () => {
				appClose();
			},
			okLabel: t("common.yes"),
			cancelCallback: () => {},
			cancelLabel: t("common.cancel") ?? ""
		});
	};
	const handleReloadApp = () => {
		appReload();
	};

	const handleSavePin = (newPin: string | number): void => {
		// PIN administrator and PIN operator must be different
		if (newPin.toString() === currentPinOperator) {
			message({
				title: t("common.crud.edit", { item: t("system.admin.access.pin") }),
				description: t("system.admin.access.alertPinDifferent"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		} else {
			setCurrentPin(newPin.toString());
		}
	};

	const handleSavePinOperator = (newPinOperator: string | number): void => {
		// PIN administrator and PIN operator must be different
		if (newPinOperator.toString() === currentPin) {
			message({
				title: t("common.crud.edit", { item: t("system.admin.access.pin") }),
				description: t("system.admin.access.alertPinDifferent"),
				okCallback: () => {},
				okLabel: t("common.ok")
			});
		} else {
			setCurrentPinOperator(newPinOperator.toString());
		}
	};

	return (
		<Paper sx={{ p: "1.5rem 0" }} elevation={0}>
			{isAdmin ? (
				<>
					<AdminDivider label={t("system.admin.access.auth")} />
					<Box>
						<Typography>
							{t("system.admin.access.restaurantId")}: {restaurantId}
						</Typography>
						<br></br>
						<Button variant="contained" color="secondary" onClick={handleLogout} endIcon={<LogoutRounded />}>
							{t("common.logout")}
						</Button>
					</Box>
					<AdminDivider label={t("common.crud.edit", { item: t("system.admin.access.pin") })} />
					<Box>
						<InputSave
							color="secondary"
							label={t("system.admin.access.pinAdministrator")}
							value={currentPin}
							onSave={handleSavePin}
							type="number"
							InputProps={{ inputProps: { min: 0, max: 9999, maxLength: 4, minLength: 4 } }}
						/>
					</Box>
					&nbsp;
					<Box>
						<InputSave
							color="secondary"
							label={t("system.admin.access.pinOperator")}
							value={currentPinOperator}
							onSave={handleSavePinOperator}
							type="number"
							InputProps={{ inputProps: { min: 0, max: 9999, maxLength: 4, minLength: 4 } }}
						/>
					</Box>
				</>
			) : null}

			<AdminDivider label={t("system.admin.access.updates")} />

			<Box>
				<Button variant="contained" color="secondary" onClick={handleUpdates} endIcon={<Cloud />}>
					{t("system.admin.access.checkForUpdates")}
				</Button>
				<br />
				<br />
				<Typography>
					{updatesFound !== null ? updatesFound ? t("system.admin.access.updatesFound") : t("system.admin.access.noUpdates") : <br />}
				</Typography>
			</Box>

			<AdminDivider label={t("system.admin.access.closeApp")} />

			<Box>
				<Button variant="contained" color="error" onClick={handleCloseApp} endIcon={<Close />} sx={{ mr: "1.5rem", width: "15rem" }}>
					{t("system.admin.access.closeApp")}
				</Button>
				<Button variant="contained" color="error" onClick={handleReloadApp} endIcon={<Refresh />} sx={{ ml: "1.5rem", width: "15rem" }}>
					{t("system.admin.access.reloadApp")}
				</Button>
			</Box>
		</Paper>
	);
};

export default memo(AdminAccess);
