import { HttpStatusCode } from "axios";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Cc, LogChannel, PaymentRequestStatus, PrinterType } from "../../../../constants";
import useCcPrinter from "../../../../services/Coatcheck/useCcPrinter";
import useRtPrinter from "../../../../services/Rt/useRtPrinter";
import { PrintCcResponse, PrintRtResponse } from "../../../../types";
import { useLogger } from "../../../../utils/useLogger";
import { useMessage } from "../../../MessageHandler/MessageService";
import { useTheme } from "../../../Theme/ThemeWrapper";

interface CheckCashSystemProps {
	isStripePaymentRN: boolean;
	setRequestStatus: Dispatch<SetStateAction<PaymentRequestStatus>>;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	cancelOrder: () => void;
}
const CheckCashSystem = ({ isStripePaymentRN, setRequestStatus, setIsLoading, cancelOrder }: CheckCashSystemProps) => {
	const { settings } = useTheme();
	const { t } = useTranslation();
	const message = useMessage();
	const { warn } = useLogger();
	const { RT } = useRtPrinter();
	const { CC } = useCcPrinter();

	const check = useCallback(async () => {
		const handleError = () => {
			warn("CheckCashSystem error", LogChannel.payment);
			message({
				title: "",
				description: t("system.error.cashSystem.statusError"),
				okCallback: () => {
					check();
				},
				okLabel: t("common.retry"),
				cancelCallback: () => cancelOrder(),
				cancelLabel: t("common.cancel") ?? ""
			});
		};

		setIsLoading(true);

		if (settings.printerType === PrinterType.basiq) {
			await RT.cashSystemCheckStatus()
				.then((response: PrintRtResponse) => {
					if (
						response.status === HttpStatusCode.Ok &&
						response.data.status.error === false &&
						response.data.status.inattivita === false &&
						response.data.status.key === 1
					) {
						setRequestStatus(isStripePaymentRN ? PaymentRequestStatus.creatingIntent : PaymentRequestStatus.creatingIntentAndActivateReader);
					} else {
						handleError();
					}
				})
				.catch(() => handleError())
				.finally(() => setIsLoading(false));
		} else {
			await CC.printerCheckStatus()
				.then((response: PrintCcResponse) => {
					if (response?.data?.result === Cc.ErrorCode.noError) {
						setRequestStatus(isStripePaymentRN ? PaymentRequestStatus.creatingIntent : PaymentRequestStatus.creatingIntentAndActivateReader);
					} else {
						handleError();
					}
				})
				.catch(() => handleError())
				.finally(() => setIsLoading(false));
		}
	}, [isStripePaymentRN, setRequestStatus, setIsLoading, message, cancelOrder, t, RT, CC, warn, settings.printerType]);

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		check();
	}, []);

	return null;
};

export default CheckCashSystem;
