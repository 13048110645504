import { Box, Paper, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Dispatch, memo, MouseEvent, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getPrinterTypeName, PrinterType } from "../../../constants";
import { MenuTicketFormat, SettingsConfig, Vat } from "../../../types";
import AdminDivider from "../../Layout/Divider/AdminDivider";
import ToggleWithLabel from "../../Layout/Toggle/ToggleWithLabel";
import { useTheme } from "../../Theme/ThemeWrapper";
import AdminSettingsCc from "./AdminSettingsCc";
import AdminSettingsRt from "./AdminSettingsRt";

interface AdminSettingsPrinterProps {
	isAdmin: boolean;
	currentSettings: SettingsConfig;
	setCurrentSettings: Dispatch<SetStateAction<SettingsConfig>>;
	settingToggler: (key: keyof SettingsConfig) => void;
	menuVatList: Vat[];
	menuTickets: MenuTicketFormat[];
}

const AdminSettingsPrinter = ({ isAdmin, currentSettings, setCurrentSettings, settingToggler, menuVatList, menuTickets }: AdminSettingsPrinterProps) => {
	const { t } = useTranslation();
	const { theme } = useTheme();

	const handlePrinterTypeChange = useCallback(
		(event: MouseEvent<HTMLElement>, value: string) => {
			if (value !== null) {
				setCurrentSettings((prev: SettingsConfig) => ({ ...prev, printerType: value as PrinterType }));
			}
		},
		[setCurrentSettings]
	);

	const handleCashSystemEnabledChange = useCallback(() => {
		setCurrentSettings((prev: SettingsConfig) => {
			const newIsCashSystemEnabled: boolean = !prev.isCashSystemEnabled;

			return {
				...prev,
				isCashSystemEnabled: newIsCashSystemEnabled,
				isFiscalMode: newIsCashSystemEnabled === false ? false : prev.isFiscalMode,
				printDigitalReceipt: newIsCashSystemEnabled === false ? false : prev.printDigitalReceipt,
				skipTallonRecap: newIsCashSystemEnabled === true ? false : prev.skipTallonRecap
			};
		});
	}, [setCurrentSettings]);

	return (
		<>
			<AdminDivider label={t("system.admin.settings.printers")} />
			<ToggleWithLabel
				color="success"
				checked={currentSettings.isCashSystemEnabled}
				onChange={handleCashSystemEnabledChange}
				disabled={!isAdmin}
				label={t("system.admin.settings.enablePrinters")}
				sx={{ my: 3 }}
			/>

			{currentSettings.isCashSystemEnabled ? (
				// 	<Typography>{t("system.admin.settings.selectPrinterType")}</Typography>

				<Paper
					elevation={0}
					sx={{ backgroundColor: theme.systemTheme.palette.background.paper, border: `0.0125rem solid ${theme.systemTheme.palette.grey.A400}` }}
				>
					<Stack direction={"row"} alignItems={"center"}>
						<ToggleButtonGroup
							size="small"
							value={currentSettings.printerType}
							exclusive
							onChange={handlePrinterTypeChange}
							className="toggleButtonGroup"
						>
							{Object.values(PrinterType).map((printerType: PrinterType) => {
								const isSelected: boolean = currentSettings.printerType === printerType;
								const formatBg = {
									backgroundColor: isSelected ? `${theme.systemTheme.palette.primary.main} !important` : undefined,
									color: isSelected ? `${theme.systemTheme.palette.primary.contrastText} !important` : undefined
								};

								return (
									<ToggleButton key={printerType} value={printerType} sx={formatBg}>
										{getPrinterTypeName(printerType)}
									</ToggleButton>
								);
							})}
						</ToggleButtonGroup>
					</Stack>
					<Box sx={{ m: 2, py: 4, px: 2, backgroundColor: theme.systemTheme.palette.background.paper }}>
						{currentSettings.printerType === PrinterType.basiq && (
							<AdminSettingsRt
								isAdmin={isAdmin}
								currentSettings={currentSettings}
								setCurrentSettings={setCurrentSettings}
								menuVatList={menuVatList}
								settingToggler={settingToggler}
							/>
						)}
						{currentSettings.printerType === PrinterType.coatcheck && (
							<AdminSettingsCc
								isAdmin={isAdmin}
								currentSettings={currentSettings}
								setCurrentSettings={setCurrentSettings}
								menuTickets={menuTickets}
							/>
						)}
					</Box>
				</Paper>
			) : null}
		</>
	);
};

export default memo(AdminSettingsPrinter);
