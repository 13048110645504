import { cloneDeep } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PrintingQueueCc, PrintingResultCc } from "../../types";
import { getStorageObject, setStorageObject } from "../../utils/StorageWrapper";
import { useCc } from "./useCc";

/**
 * Handles the Printing Queue for a specific cash System IP.
 *
 * It leverages the local storage to store unprinted printing items.
 * It handles also common Cash System error states (e.g. out of paper).
 * By calling the "process" function you can try to print afterward
 * the printing items not printed during the usual app flow.
 *
 * @returns
 */
const useCcPrinter = () => {
	const CC = useCc();

	const defaultQueue = useMemo(() => {
		return { queue: [] };
	}, []);

	const [isCashSystemInError, setIsCashSystemInError] = useState<boolean>(false);
	const [isCashSystemOutOfPaper, setCashSystemIsOutOfPaper] = useState<boolean>(false);
	const [isProcessed, setIsProcessed] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [printingQueue, setPrintingQueue] = useState<PrintingQueueCc>(defaultQueue);
	const [queueInitialLength, setQueueInitialLength] = useState<number>(0);
	const [queueCurrentLength, setQueueCurrentLength] = useState<number>(0);

	/**
	 * Retrieve the printing queue from the local storage
	 */
	const retrieveFromStorage = useCallback((): PrintingQueueCc => {
		return (getStorageObject("printingQueueCc") as PrintingQueueCc) ?? defaultQueue;
	}, [defaultQueue]);

	/**
	 * Put the printing queue into the local storage
	 */
	const putIntoStorage = useCallback((printingQueue: PrintingQueueCc): void => {
		return setStorageObject("printingQueueCc", printingQueue);
	}, []);

	/**
	 * This function empties the printing queue and reset all the state flags
	 */
	const reset = useCallback((): void => {
		setIsCashSystemInError(false);
		setCashSystemIsOutOfPaper(false);
		setPrintingQueue(defaultQueue);
		putIntoStorage(defaultQueue);
		setIsProcessed(true);
		setQueueInitialLength(0);
		setQueueCurrentLength(0);
	}, [defaultQueue, putIntoStorage]);

	/**
	 * it process the queue currently stored in local storage
	 */
	const process = useCallback((): void => {
		// reset flags
		setIsCashSystemInError(false);
		setCashSystemIsOutOfPaper(false);
		setIsProcessed(false);
		setQueueCurrentLength(0);

		// if non empty queue, process it
		const localQueue = retrieveFromStorage();
		if (localQueue.queue.length === 0) {
			setIsProcessed(true);
			return;
		}
		setQueueInitialLength(localQueue.queue.length);
		setQueueCurrentLength(localQueue.queue.length);
		setPrintingQueue(localQueue);
	}, [retrieveFromStorage]);

	const delay = (time: number) => {
		return new Promise((resolve) => setTimeout(resolve, time));
	};

	useEffect(() => {
		/**
		 * print out first item in queue. In case of success remove the item from the queue and updated state and localStorage
		 */
		const printFirstItem = async () => {
			const localQueue = cloneDeep(printingQueue);
			await CC.printSingleItem(localQueue.queue[0])
				.then(async (printingResult: PrintingResultCc) => {
					if (printingResult.isInError) {
						setIsCashSystemInError(true);
					} else {
						if (printingResult.isPrinted) {
							localQueue.queue.shift();
							setPrintingQueue({ ...printingQueue, queue: localQueue.queue });
							setQueueCurrentLength(localQueue.queue.length);
							putIntoStorage({ ...printingQueue, queue: localQueue.queue });
							if (localQueue.queue.length === 0) setIsProcessed(true);
						} else {
							if (printingResult.isPrinting) {
								await delay(1000);
							} else {
								setCashSystemIsOutOfPaper(true);
							}
						}
					}
				})
				.finally(() => {
					setIsProcessing(false);
				});
		};

		if (printingQueue.queue.length === 0 || isCashSystemInError || isCashSystemOutOfPaper) return;

		if (isProcessing) return;

		setIsProcessing(true);
		printFirstItem();
	}, [printingQueue, isCashSystemInError, isCashSystemOutOfPaper, putIntoStorage, CC, isProcessing]);

	return {
		CC,
		isCashSystemOutOfPaper,
		isCashSystemInError,
		isPrintingQueueProcessed: isProcessed,
		queueInitialLength,
		queueCurrentLength,
		resetPrintingQueue: reset,
		processPrintingQueue: process,
		retrievePrintingQueue: retrieveFromStorage,
		storePrintingQueue: putIntoStorage
	};
};

export default useCcPrinter;
