import { SettingsConfig } from "../types";

export const calculateKioskIdSetting = (settingsConfigList: SettingsConfig[], kioskId?: number): SettingsConfig => {
	const initialKioskId: number = kioskId === undefined ? 0 : kioskId;
	let localKioskId: number = initialKioskId ? initialKioskId : parseInt(localStorage.getItem("kioskId") ?? "0");
	let chosenSettings: SettingsConfig = settingsConfigList[0];

	// Starting with version 1.4.3 the KioskIds range is [101 - 199]. Previously it was [1 - 99].
	// Older Settings objects must be adapted accordingly (+100)
	if (localKioskId > 0 && localKioskId < 101) {
		localKioskId = localKioskId + 100;
	}

	// if in config there are only 2 settings (i.e. the default settings and just one settings configured)
	// and there is no kioskId in local storage
	if (settingsConfigList.length === 2 && localKioskId === 0) {
		chosenSettings = settingsConfigList[1]; //... then choose the only one configured
	} else {
		// otherwise get the configuration related to the localKioskId in storage
		const localConfigKioskId = settingsConfigList.find((sc: SettingsConfig) => sc.kioskId === localKioskId);
		if (localConfigKioskId) {
			chosenSettings = localConfigKioskId;
		}
	}

	// save localKioskId in storage
	localStorage.setItem("kioskId", String(chosenSettings.kioskId));
	return chosenSettings;
};
