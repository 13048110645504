export enum LogChannel {
	generic = "@",
	router = "ROUTE",
	language = "LANG",
	cloudSync = "SYNC",
	order = "ORDER",
	payment = "PAYMENT",
	document = "DOCUMENT",
	cashSystem = "PRINT",
	authentication = "AUTH",
	boundaryError = "RUNTIME ERROR"
}
