import { Grid2 as Grid } from "@mui/material";
import { memo, ReactNode } from "react";

type Props = {
	children: ReactNode[];
	menuColumn: number;
};

const CardsWrapper = ({ children, menuColumn }: Props) => {
	const isMediumColumn: boolean = menuColumn === 2;

	return (
		<Grid container rowSpacing={isMediumColumn ? "1.5rem" : "1rem"} columnSpacing={isMediumColumn ? "3rem" : "1rem"}>
			{children}
		</Grid>
	);
};

export default memo(CardsWrapper);
